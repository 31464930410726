


import VueI18n from 'vue-i18n'
import i18n from './lang'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VDistpicker from 'v-distpicker'
// 富文本
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'


Vue.use(VueI18n)
export default {
	component: {
		VDistpicker
	}
}

Vue.use(VueQuillEditor)
import axios from 'axios'
Vue.prototype.url = "https://app.bjtykj.com/api"; //联调域名
// Vue.prototype.url = 'http://122.114.5.60:9080' //正式域名
Vue.prototype.$http = axios
// axios.defaults.baseURL = "http://9cn28m.natappfree.cc"; //联调域名
axios.defaults.baseURL = 'https://app.bjtykj.com/api' //正式域名
axios.defaults.headers.post['Content-Type'] = 'application/json'
let Authorization = ''
// console.log("zoulema ajsdjf ",JSON.parse(localStorage.getItem('userinfo')));
Authorization = JSON.parse(localStorage.getItem('userinfo'))
console.log(Authorization, '！！！！！！！@@@@@@@@')
// console.log(JSON.parse(localStorage.getItem('userinfo')));
axios.interceptors.request.use((config) => {
	if (!config.notoken) {
		if (Authorization) {
			config.headers['user-token'] = JSON.parse(localStorage.getItem('userinfo'))?JSON.parse(localStorage.getItem('userinfo')).user_token : ''
			// config.headers['user-token'] =''
				
				
				
		} else {
			config.headers['user-token'] = ''
			
		}
		// console.log(this.$i18n.locale,'到这了1111111111111111');
		// config.headers['Accept-Language'] = 'en'
		// config.headers['lang'] = 'en'
		console.log(localStorage.getItem('lang'),'A$$$$$$$$$$$$$$$$$$$$');
		config.headers['lang'] = localStorage.getItem('lang')
	}
	return config
})

axios.interceptors.response.use((response) => {
	console.log(response, 'jiekosu skks', response.data.code);
	// if (response.data.code == '-201') {
	// 	localStorage.clear()
	// 	router.push({
	// 		name: 'LoginIdex'
	// 	})
	// }
	return response
})

import qs from 'qs'
Vue.prototype.qs = qs

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app')
