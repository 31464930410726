<template>
	<div class="hello">
		<div id="main" style="height: 290px; width: 550px;"></div>
		
	</div>
</template>

<script>
	import * as echarts from 'echarts'
	export default {
		name: 'HelloWorld',
		props: {
			msg: String
		},
		data() {
			return {
				tableData: Array(20).fill(item)
			}
		},
		mounted() {
			this.initCharts()
		},
		methods: {
			// 柱状图
			initCharts() {
				// 基于准备好的dom，初始化echarts实例
				    var myChart = echarts.init(document.getElementById("main"));
				// 指定图表的配置项和数据
				var option = {
					title: {
						text: '月销量'
					},
					// 提示框
					tooltip: {},
					// 图例
					legend: {
						data: ['销量']
					},
					// 表示x轴坐标
					xAxis: {
						data: ['oppo', 'vivo', 'iphone', '小米', '三星', '魅族']
					},
					// 表示y轴坐标
					yAxis: {},
					// 
					series: [{
						name: '销量',
						type: 'line',
						data: [3500, 2200, 4500, 6500, 200, 3000]
					}]
				};
				myChart.setOption(option);
			},
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	.el-row {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.el-col {
		border-radius: 4px;
	}

	.bg-purple-dark {
		background: #99a9bf;
	}

	.bg-purple {
		background: #d3dce6;
	}

	.bg-purple-light {
		background: #e5e9f2;
	}

	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}

	.row-bg {
		padding: 10px 0;
		background-color: #f9fafc;
	}

	.el-header,
	.el-footer {
		background-color: #B3C0D1;
		color: #333;
		text-align: center;
		line-height: 60px;
	}

	.el-aside {
		background-color: #D3DCE6;
		color: #333;
		text-align: center;
		line-height: 200px;
	}

	.el-main {
		background-color: #E9EEF3;
		color: #333;
		text-align: center;
		line-height: 160px;
	}

	body>.el-container {
		margin-bottom: 40px;
	}

	.el-container:nth-child(5) .el-aside,
	.el-container:nth-child(6) .el-aside {
		line-height: 260px;
	}

	.el-container:nth-child(7) .el-aside {
		line-height: 320px;
	}
</style>
