<template>
	<div id="app">
		<router-view :key="key" />
	<!-- 	<div class="guding" v-if="token">
			<div class="gd_kefu" @click="tolianxi">
				<img src="./image/kefuerji.png" style="width: 24px;height: 24px;margin-top: 24px;margin-left: 30px;"
				<div style="font-size: 14px;color: #333;text-align: center;margin-top: 11px;margin-bottom: 24px;">
					{{$t('publicheader.lxkefu')}}
				</div>
			</div>
			<div class="gd_kefu" style="margin-top: 20px;" @click="backTop">
				<img src="./image/xiangshang.png" style="width: 24px;height: 24px;margin-top: 24px;margin-left: 30px;"
					alt="">
				<div style="font-size: 14px;color: #333;text-align: center;margin-top: 11px;margin-bottom: 24px;	">
					{{$t('publicheader.huidingbu')}}
				</div>
			</div>
		</div> -->
		<el-dialog :title="$t('publicheader.lxkf')" :visible.sync="kefustatus" width="534px">
			<img src="./image/kefu.png" style="width: 160px;height: 160px;margin-left: 167px;" alt="">
			<div style="font-size: 16px;color: #333333;text-align: center;">
				{{$t('publicheader.gzshijian')}} {{detail.service_time}}
			</div>
			<div style="display: flex;align-items: center;margin-left: 137px;margin-top: 26px;">
				<img src="./image/dianhua.png" style="width: 22px;height: 22px;" alt="">
				<div style="margin-left: 8px;font-size: 16px;color:	 #333333;width: 180px;">
					{{$t('publicheader.dianhua')}} {{detail.service_phone}}
				</div>
				<div class="boda" @click="toboda(detail.service_phone)">
					{{$t('publicheader.boda')}}
				</div>
			</div>
			<div style="display: flex;align-items: center;margin-left: 137px;margin-top: 26px;">
				<img src="./image/youxiang.png" style="width: 22px;height: 22px;" alt="">
				<div style="margin-left: 8px;font-size: 16px;color: #333333;width: 180px;">
					{{$t('publicheader.youxiang')}} {{detail.service_email}}
				</div>
				<div class="boda" @click="tocopy(detail.service_email)">
					{{$t('publicheader.fuzhi')}}
				</div>
			</div>
		</el-dialog>
		<meta name="format-detection" content="telephone=yes" />
	</div>
</template>
<script>
	export default {
		data() {
			return {
				kefustatus: false,
				showBtn: false,
				detail: {},
				token: ''
			};
		},
		computed: {
			key() {
				return this.$route.path + Math.random();
			}
		},
		mounted() {
			window.addEventListener("scroll", this.showbtn, true)
			this.getiphone()
			this.token = JSON.parse(localStorage.getItem('userinfo')) ? JSON.parse(localStorage.getItem('userinfo')).user_token : ''
			this.$i18n.locale = localStorage.getItem('lang')?localStorage.getItem('lang'): 'zn'
			// localStorage.setItem('lang', 'zn')
			
		},
		methods: {
			toboda(iphone) {
				console.log(iphone, 'll');
				window.location.href = 'tel://' + iphone
			},
			tocopy(data) {
				let url = data;
				let oInput = document.createElement('input');
				oInput.value = url;
				document.body.appendChild(oInput);
				oInput.select(); // 选择对象;
				console.log(oInput.value)
				document.execCommand("Copy"); // 执行浏览器复制命令
				this.$message({
					message: '复制成功',
					type: 'success'
				});
				oInput.remove()
			},
			getiphone() {
				this.$http({
					url: '/m2958/642101158cb67',
					method: 'post',
					// notoken: true,
					data: JSON.stringify({
					}),
				}).then((res) => {
					if (res.data.code == 1) {
						this.detail = res.data.data;
					} else {

					}
				})
			},
			tolianxi() {
				this.kefustatus = true
			},
			// 回到顶部
			showbtn() {
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (scrollTop > 1000) {
					this.showBtn = true;
				} else {
					this.showBtn = false;
				}
			},
			backTop() {
				var timer = setInterval(function() {
					let osTop = document.documentElement.scrollTop || document.body.scrollTop;
					let ispeed = Math.floor(-osTop / 5);
					document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
					this.isTop = true;
					if (osTop === 0) {
						clearInterval(timer)
					}
				}, 8);
			},

		},
	}
</script>

<style lang="less">
	body {
		background-color: #F2F2F2 !important;
	}

	* {
		margin: 0;
		padding: 0;
	}


	.cusorPointer {
		cursor: pointer;
	}

	.gd_kefu {
		width: 84px;
		// height: 92px;
		background: #FFFFFF;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
		border: 1px solid #EEEEEE;
	}

	.guding {
		position: fixed;
		top: 400px;
		right: 40px;
		z-index: 9999999;
	}

	.boda {
		width: 56px;
		height: 28px;
		background-color: #FF6C34;
		border-radius: 16px 16px 16px 16px;
		opacity: 1;
		text-align: center;
		line-height: 28px;
		color: #FFFFFF;
		z-index: 999999;

	}
</style>
