<template>
	<div class="footer_content">
		<div class="footer_one">
			<div class="footer_one_ls">
				<div class="footer_one_l_bottom" style="display: flex;align-items: center;width:500px;">
					<img style="width: 60px;height: 60px;" src="../image/logo.png" alt="">
					<div class="" style="margin-top: 6px;rgba(255, 255, 255, 1);width: 420px;margin-left: 12px;">
						<div style="font-size: 22px;color: rgba(255, 255, 255, 1);width: 420px;    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;

">{{$t('publicheader.北京田圆科技')}}</div>
						<div style="font-size: 14px;color: rgba(255, 255, 255, 1);margin-top: 14px;width: 420px;    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;">
							<!-- {{$t('publicheader.消防技术交流网')}} -->
						</div>
					</div>
				</div>
			</div>
			<div class="footer_one_r">
				<div class="footer_one_r_two">
					<!-- <p style="font-size: 16px;font-weight: bold;color: rgba(255, 255, 255, 1);">
						{{$t('publicheader.服务热线')}}
					</p> -->
				</div>
				<div class="footer_one_r_one" style="cursor: pointer;">
					<div class="footer_one_r_one_list">
						<span style="font-size: 16px;color: rgba(255, 255, 255, 1);"> {{detail.tel}}</span>
					</div>
				</div>
			</div>
			<div class="footer_one_r" style="margin-right:18px">
				<div class="footer_one_r_two">
				<!-- 	<p style="font-size: 16px;font-weight: bold;color: rgba(255, 255, 255, 1);">
						{{$t('publicheader.电子邮箱')}}
					</p> -->
				</div>
				<div class="footer_one_r_one" style="cursor: pointer;">
					<div class="footer_one_r_one_list">
						<span style="font-size: 16px;color: rgba(255, 255, 255, 1);">{{detail.web_site_email}}</span>
					</div>
				</div>
			</div>
			<!-- <div class="footer_one_r">
				<div class="footer_one_r_two">
					<p style="font-size: 16px;font-weight: bold;color: rgba(255, 255, 255, 1);" @click="toguanyu()">
						{{$t('publicheader.关于我们')}}
					</p>
				</div>
				<div class="footer_one_r_one" style="cursor: pointer;">
					<div class="footer_one_r_one_list" @click="toguanyu()">
						<span style="font-size: 16px;color: rgba(255, 255, 255, 1);">{{$t('publicheader.关于我们')}}</span>
					</div>
				</div>
			</div> -->
			<div class="footer_one_r">
				<div class="footer_one_r_two">
					<p style="font-size: 16px;font-weight: bold;color: rgba(255, 255, 255, 1);">
						{{$t('publicheader.常见问题')}}
					</p>
				</div>
				<div class="footer_one_r_one" style="cursor: pointer;">
					<div class="footer_one_r_one_list" @click="tozhanghao()">
						<span style="font-size: 16px;color: rgba(153, 153, 153, 1);">{{$t('publicheader.账号管理')}}</span>
					</div>
				</div>
				<div class="footer_one_r_one" style="cursor: pointer;">
					<div class="footer_one_r_one_list" @click="tohuiyuan()">
						<span style="font-size: 16px;color: rgba(153, 153, 153, 1);">{{$t('publicheader.会员开通')}}</span>
					</div>
				</div>
				<div class="footer_one_r_one" style="cursor: pointer;">
					<div class="footer_one_r_one_list" @click="tozqiyerenzheng()">
						<span style="font-size: 16px;color: rgba(153, 153, 153, 1);">{{$t('publicheader.企业认证')}}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- 		<div class="xian">
		</div> -->
	<div style="font-size: 14px;text-align: center;color: rgba(153, 153, 153, 1);padding-top: 0px;">

			{{$t('publicheader.北京市通州区光华路甲')}}
		</div>
		<div style="font-size: 14px;text-align: center;color: rgba(153, 153, 153, 1);padding-top: 8px;">
			{{detail.web_site_icp}}
		</div>
		<el-dialog title="联系客服" :visible.sync="kefustatus" width="534px">
			<img src="../../src/image/kefu.png" style="width: 160px;height: 160px;margin-left: 167px;" alt="">
			<div style="font-size: 16px;color: #333333;text-align: center;">
				{{$t('publicheader.工作时间')}} {{detail.service_time}}
			</div>
			<div style="display: flex;align-items: center;margin-left: 137px;margin-top: 26px;">
				<img src="../../src/image/dianhua.png" style="width: 22px;height: 22px;" alt="">
				<div style="margin-left: 8px;font-size: 16px;color: #333333;width: 180px;">
					{{$t('publicheader.电话')}} {{detail.service_phone}}
				</div>

				<div class="boda" @click="toboda(detail.service_phone)">
					{{$t('publicheader.拨打')}}
				</div>
			</div>
			<div style="display: flex;align-items: center;margin-left: 137px;margin-top: 26px;">
				<img src="../../src/image/youxiang.png" style="width: 22px;height: 22px;" alt="">
				<div style="margin-left: 8px;font-size: 16px;color: #333333;width: 180px;">
					{{$t('publicheader.邮箱')}} {{detail.service_email}}
				</div>

				<div class="boda" @click="tocopy(detail.service_email)">
					{{$t('publicheader.复制')}}
				</div>
			</div>
		</el-dialog>
		<el-dialog :title="zhengze==2?$t('publicheader.隐私政策'):$t('publicheader.免责条款')" :visible.sync="ysstatus"
			width="798px" center>
			<div class="ysxian">
			</div>
			<div style="margin: 24px;">
				<span v-html="flList.neirong"></span>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				kefustatus: false,
				ysstatus: false,
				flList: {},
				detail: {},
				zhengze: '',
				token: '',
				index1: '',
				index2: '',
				index3: ''

			};
		},

		mounted() {
			this.token = JSON.parse(localStorage.getItem('userinfo')) ? JSON.parse(localStorage.getItem('userinfo'))
				.user_token : '';
			console.log(this.token, '##');

			this.getiphone()
		},


		methods: {
			toboda(iphone) {
				console.log(iphone, 'll');
				window.location.href = 'tel://' + iphone
			},

			tocopy(data) {
				let url = data;
				let oInput = document.createElement('input');
				oInput.value = url;
				document.body.appendChild(oInput);
				oInput.select(); // 选择对象;
				console.log(oInput.value)
				document.execCommand("Copy"); // 执行浏览器复制命令
				this.$message({
					message: '复制成功',
					type: 'success'
				});
				oInput.remove()
			},

			getiphone() {
				this.$http({
					url: '/m7019/66dad60342a84',
					method: 'post',
					// notoken: true,
					data: JSON.stringify({

					}),
				}).then((res) => {
					if (res.data.code == 1) {
						this.detail = res.data.data;
					} else {

					}
				})

			},

			tozhanghao() {

				if (this.token) {
					this.$router.push({
						name: 'guanyuwomen',
						query: {
							type1: 0
						}
					})
				} else {
					this.$message.error('请先登录');
					this.$router.push({
						name: 'LoginIdex',
						query: {
							type: ''
						}
					})
				}

			},
			tohuiyuan() {

				if (this.token) {
					this.$router.push({
						name: 'guanyuwomen',
						query: {
							type2: 1
						}
					})
				} else {
					this.$message.error('请先登录');
					this.$router.push({
						name: 'LoginIdex',
						query: {
							type2: ''
						}
					})
				}

			},
			tozqiyerenzheng() {

				if (this.token) {
					this.$router.push({
						name: 'guanyuwomen',
						query: {
							type3: 2
						}
					})
				} else {
					this.$message.error('请先登录');
					this.$router.push({
						name: 'LoginIdex',
						query: {
							type: ''
						}
					})
				}
			},


			getflList() {
				this.$http({
					url: '/m2958/64228d609f6e8',
					method: 'post',
					// notoken: true,
					data: JSON.stringify({
						aid: this.zhengze
					}),
				}).then((res) => {
					console.log(res);
					if (res.data.code == 1) {
						this.flList = res.data.data;
					} else {

					}
				})
			},
			// 跳转关于
			toguanyu() {
				console.log('@@@@');
				this.$router.push({
					name: 'guanyuwomen',
					query: {
						type: 1
					}
				})
				// if(this.token) {
				// 	this.$router.push({
				// 		name: 'guanyuwomen',
				// 		query: {
				// 			type: 1
				// 		}
				// 	})
				// }else {
				// 	this.$message.error('请先登录');
				// 	this.$router.push({
				// 		name: 'LoginIdex',
				// 		query: {
				// 			type: ''
				// 		}
				// 	})
				// }
			},
			toyingsi(zhengze) {
				this.zhengze = zhengze
				this.ysstatus = true
				this.getflList()
			},
			tolianxi() {
				this.kefustatus = true
			}
		}
	}
</script>

<style scoped>
	.footer_content {
		width: 100%;
		height: 226px;
		clear: both;
		background: rgba(64, 64, 64, 1);
	}

	.footer_one {
		width: 1350px;
		margin: 0 auto;
		display: flex;
		justify-content: space-around;
	}

	.footer_one_l {
		width: 20%;
		overflow: hidden;
		float: left;
		margin-top: 28px;
	}

	.footer_one_ls {
		width: 45%;
		overflow: hidden;
		float: left;
		margin-top: 28px;
	}

	.footer_one_l_header {
		width: 100%;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		line-height: 20px;
		color: #EFF4FF;
		position: relative;
	}

	.footer_one_l_header_border {
		width: 32px;
		height: 0px;
		border: 2px solid #E53B3C;
		margin-top: 17px;
	}

	.footer_one_l p {
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		line-height: 14px;
		color: #BFBFBF;
		margin-top: 26px;
	}

	.footer_one_l p img {
		width: 14px;
		height: 14px;
		margin-right: 10px;
		position: relative;
		top: 2px;
	}

	.footer_one_l_bottom {
		width: 100%;
		overflow: hidden;
		margin-top: 12px;
	}

	.footer_one_l_bottom img {
		width: 48px;
		height: 48px;
		float: left;
	}

	.footer_one_l_bottom_r {
		width: auto;
		float: left;
		margin-left: 10px;
		/* margin-top: 30px; */
		font-size: 8px;
		height: 20px;
		line-height: 20px;
	}

	.footer_one_l_bottom_r span {
		font-size: 8px;

		font-weight: 400;
		color: #ABAFC1;
		/* 		position: relative;
		top: -20px; */

	}

	.footer_one_l_bottom_r span {
		/* 	font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ABAFC1;
		position: relative;
		top: -15px; */
	}

	.footer_one_c {
		width: 13%;
		/* overflow: hidden;
		float: left; */
		margin: 0 auto;
		margin-top: 52px;
		margin-right: 80px;
	}

	.footer_one_c_list {
		/* width: 43%; */
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 20px;
		color: #AEAEAE;
		text-align: center;
		float: left;
		margin-top: 56px;
	}

	.footer_one_c_list img {
		width: 88px;
		height: 88px;
		display: block;
		margin: 0 auto 15px auto;
	}

	.footer_one_r {
		width: 220px;
		margin-left: 50px;
		margin-top: 12px;
	}

	.footer_one_r_one_list {
		width: 198px;
		
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 20px;
		color: #FFFFFF;
		float: right;
		/* padding: 0 10px 0 10px; */
		position: relative;
		margin-top: 16px;
	}

	.footer_one_r_one_list_border {
		width: 1px;
		height: 14px;
		border-right: 1px solid #FFFFFF;
		position: absolute;
		right: 0;
		top: 3px;
	}

	.footer_one_r_two {
		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 12px;
		color: #FFFFFF;
		/* text-align: right; */
		margin-top: 32px;
		line-height: 20px;
	}

	.footer_one_r_two a img {
		width: 14px;
		height: 14px;
		display: block;
		float: left;
		margin-left: 200px;
	}

	.xian {
		width: 1200px;
		height: 1px;
		background: #444444;
		margin: 0 auto;
		margin-top: 32px;
	}

	.boda {
		width: 56px;
		height: 28px;
		background-color: #FF6C34;
		border-radius: 16px 16px 16px 16px;
		opacity: 1;
		text-align: center;
		line-height: 28px;
		color: #FFFFFF;
		z-index: 999999;

	}

	.ysxian {
		width: 768px;
		height: 1px;
		border-bottom: 1px solid #E2E2E2;
		margin: 0 auto;

	}
</style>