<template>
	<el-container>
		<el-header style="height: 80px;margin-top: 12px;">
			<div @click.stop class="header-content"
				style="display: flex;align-items:center;position: relative;width: 1200px;">
				<!-- 未登陆 -->
				<!-- <div class="header_button" style="">
					<div @click="toopen" style="display: flex;align-items: center;">
						<img style="width: 14px;height: 12px;margin-left: 32px;" src="../image/shangpin.png" alt="">
						<div style="margin-left: 14px;">{{$t('publicheader.qbsp')}}</div>
					</div>
					<div class="header_box" v-if="openstauts">
						<div v-for="(item,index) in flList" :key="index" style="text-align: center;margin-top: 20px;"
							class="header_box_one">
							<div style="margin-left: 44px;display: flex;position: relative;display: flex;margin-bottom: 32px;"
								@click.stop="topxitong(item.aid,item.children)">
								<div>{{item.name}}</div>
								
								<img src="../image/jiantou.png"
									style="width: 7px;height: 12px;margin-top: 14px;margin-left: 12px;" alt="">
							</div>
						</div>
						<div class="header_box1" v-if="xtstauts">
							<div class="header_box1_bai" style="display: flex;flex-wrap: wrap;">
								<div v-for="(item,index) in childrenList" style="height: 20px;width: 180px;border-right: 1px solid #666666;line-height: 20px;margin-left: 6px;margin-top: 4px;overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;" @click="tofeilei(item.aid)">
									{{item.name}}
								</div>
							</div>
						</div>
					</div>
				</div> -->

				<ul class="header-content-one" style="margin-left: 20px;margin-top: 24px;">
					<li class="header_onelist" :class="{'header_onelist_now':headerIndex==index}"
						v-for="(item,index) in header_list" @mousemove="headerMouse(index)" @mouseout="headerout"
						@click="gopage(index)">
						{{$t(item)}}

						<!-- <div class="header_onelist_border" v-if="headerIndex == index"></div> -->
						<!-- <ul class="header-content-two" v-if="index==3 && headerIndex==3">
							<li v-for="(list,smaindex) in twiceList" :class="{'header_onelist_now':twiceIndex==smaindex}" @click="twiceLick(smaindex)">{{list}}</li>
						</ul> -->
					</li>
				</ul>
				<!-- 已登陆 -->
				<!-- <ul class="header-content-one" v-else>
					<li class="header_onelist" :class="{'header_onelist_now':headerIndex==index}" v-for="(item,index) in loginheader_list"  @click="loggopage(index)">{{item}}
						<div class="header_onelist_border" v-if="headerIndex == index"></div>
						<ul class="header-content-two" v-if="index==3 && headerIndex==3">
							<li v-for="(list,smaindex) in twiceList" :class="{'header_onelist_now':twiceIndex==smaindex}" @click="twiceLick(smaindex)">{{list}}</li>
						</ul>
					</li>
				</ul> -->
			</div>
		</el-header>
	</el-container>
</template>
<script>
	export default {
		data() {
			return {
				// header_list: ['publicheader.shouye', 'publicheader.xpzx', 'publicheader.jlzx'], //未登陆头部导航
				header_list: ['publicheader.新闻资讯', 'publicheader.科技动态', 'publicheader.产品标准', 'publicheader.新产品新技术',
					'publicheader.项目展示', 'publicheader.需求发布', 'publicheader.技术交流', 'publicheader.关于我们'
				], //未登陆头部导航
				// loginheader_list:["首页","煤矿计算器","煤矿地图","新闻资讯","技术支持","关于我们"],//已登陆头部导航
				headerIndex: 0, //头部当前选择状态
				twiceList: ['产业地图', '行业咨询', '供需线索'], //二级分类数据
				twiceIndex: 9, //二级分类的当前选择状态
				user_info: "", //用户信息
				openstauts: false,
				xtstauts: false,
				zclist: ['发动', '发动机总成', '发动机总成', '发动机总成', '发动机总成', '发动机总成', '发动机总成', '发动机总成'],
				flList: [],
				pid: '',
				childrenList: []
			}
		},
		mounted() {
			if (localStorage.getItem("headerIndex")) {
				this.headerIndex = localStorage.getItem("headerIndex");
			} else {
				this.headerIndex = 0;
			}
			if (localStorage.getItem('userinfo')) {
				this.user_info = JSON.parse(localStorage.getItem('userinfo'))
			} else {
				this.user_info = ''
			}
			// this.pid = this.$route.query.aid?this.$route.query.aid: '';
			this.getflList()
			document.addEventListener("click", this.bodyCloseMenus);
		},
		beforeDestroy() {
			document.removeEventListener("click", this.bodyCloseMenus);
		},
		methods: {

			bodyCloseMenus(e) {
				let self = this;
				if (self.openstauts == true) {
					self.openstauts = false;
				}
			},

			// 跳转分类
			tofeilei(id) {
				this.$router.push({
					name: "feilei",
					query: {
						cid: id
					}
				})
			},
			// 获取分类列表
			getflList() {
				this.$http({
					url: '/m2958/62c3b236b9e99',
					method: 'post',
					// notoken: true,
					data: JSON.stringify({
						pid: this.pid,
						list_rows: 100
					}),
				}).then((res) => {
					console.log(res);
					if (res.data.code == 1) {
						this.flList = res.data.data;
					} else {

					}
				})

			},


			//鼠标移入事件
			headerMouse(index) {
				if (index == 3) {
					this.headerIndex = index;
				}
			},
			headerout() {
				this.headerIndex = localStorage.getItem("headerIndex");
			},
			toopen() {
				console.log('@@');
				this.openstauts = !this.openstauts
				this.xtstauts = false
			},
			topxitong(id, children) {
				this.xtstauts = !this.xtstauts
				this.xtstauts = true
				this.pid = id;
				this.childrenList = children;
				// this.getflList()
			},
			//未登陆一级分类点击事件
			gopage(index) {
				// localStorage.removeItem('headerIndex')
				localStorage.setItem("headerIndex", index);
				if (index == 0) {
					this.$router.push('/');
				} else if (index == 1) {
					this.$router.push('/MokefinancialIndex');
					// this.$router.push({"name":"prodectList"})
				} else if (index == 2) {
					this.$router.push('/chanpinbiaozhun');
				} else if (index == 3) {
					this.$router.push('/xinchanpin');
				} else if (index == 4) {
					this.$router.push('/xiangmuzhanshi');
				} else if (index == 5) {
					this.$router.push('/xuqiufabu');
				} else if (index == 6) {
					this.$router.push('/jishujiaoliu');
				} else if (index == 7) {
					this.$router.push('/guanyuwomen');
				} else if (index == 8) {
					if (localStorage.getItem("userinfo")) {
						this.$router.push({
							name: "workbench"
						})
					} else {
						localStorage.setItem("headerIndex", 0)
						this.$router.push({
							"name": "LoginIdex"
						})
					}
				}

			},


			//已登陆的头部点击事件
			loggopage(index) {
				localStorage.setItem("headerIndex", index);
				if (index == 0) {
					this.$router.push('/');
				} else if (index == 1) {
					this.$router.push('/counterHome');
				} else if (index == 2) {
					this.$message.error("该功能正在开发中")
				} else if (index == 3) {
					this.$message.error("该功能正在开发中")
				} else if (index == 4) {
					this.$message.error("该功能正在开发中")
				} else if (index == 5) {
					this.$router.push('/about');
				}
			},

			//二级分类点击事件
			twiceLick(index) {
				this.headerIndex = 9;
				this.twiceIndex = index;
				this.$router.push({
					name: "map"
				})
			}
		},
	}
</script>

<style scoped>
	.el-header {
		width: 100%;
		height: 100px;
		line-height: 100px;
		background-color: #fff;
		cursor: pointer;
	}

	.header-content {
		width: 1200px;
		height: 100%;
		margin: 0 auto;
	}

	.header-content-one {
		width: 100%;
		height: 100%;
	}

	.header_onelist {
		width: 100px;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 40px;
		color: #333333;
		float: left;
		margin-right: 40px;
		list-style: none;
		position: relative;
		text-align: center;
		overflow: hidden;
		-webkit-line-clamp: 1;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}
s
	.header_onelist_border {
		width: 28px;
		height: 4px;
		background: #E53B3C;
		margin: 0 auto;
		position: relative;
		top: -24px;
	}

	.header_onelist_now {
		width: 100px;
		color: #E53B3C !important;
		overflow: hidden;
		-webkit-line-clamp: 1;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}

	.header-content-two {
		width: 100%;
		background-color: #fff;
		overflow: hidden;
		box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.2);
		border-radius: 8px;
		position: relative;
		top: 0px;
		z-index: 999;
	}

	.header-content-two ul {
		width: 80%;
		height: 100%;
	}

	.header-content-two li {
		list-style: none;
		text-align: center;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 44px;
		color: #333333;
	}

	.header_button {
		width: 250px;
		height: 40px;
		background-color: #FF6C34;
		text-align: center;
		line-height: 40px;
		color: #ffffff;
		font-size: 14px;
	}

	.header_box {
		width: 192px;
		background-color: #39364D;
		z-index: 9999;
		position: absolute;
		top: 40px;


	}

	.header_box_one {
		width: 100%;
		height: 20px;
		display: flex;
		align-items: center;
		margin: 0 auto;

	}

	.header_box1 {
		width: 400px;
		/* height: 300px; */
		background-color: #ffffff;
		z-index: 9999;
		position: absolute;
		top: 0px;
		left: 160px;

	}

	.header_box1_bai {
		width: 340px;
		color: #666666;
		margin-bottom: 24px;


	}

	::v-deep input:focus {
		outline: none;
	}
</style>