// yn.js
export default {
	publicheader: {
		message: "của tôi",
		shopping: "Xin chào ~ chào mừng bạn đến trung tâm thương mại Thái Lan!",
		Order: 'Đơn hàng của tôi',
		logo: 'Trung tâm thương mại Thái Lan',
		qichepeijian: 'Dòng phụ kiện ô tô',
		zhiliang: 'Tồn tại bằng chất lượng, phát triển bằng lợi ích',
		hydenglu: 'Vui lòng đăng nhập',
		youxianghao: 'Vui lòng nhập mail',
		srmima: 'Nhập mật khẩu (6 ~ 12 chữ cái+số)',
		
		denglu: 'Đăng nhập',
		zhuce: 'Đăng ký tài khoản',
		wjmima: 'Quên mật khẩu',
		tongyi: 'Tôi đồng ý',
		zhengce: 'Thỏa thuận đăng ký người dùng" và "Chính sách bảo mật',
		zcxy: 'Thỏa thuận đăng ký người dùng',
		yszc: 'Chính sách bảo mật',
		zixun: 'Hotline hỗ trợ',
		yu: 'Với',
		man: 'Đầy đủ',
		linjuan: 'Cổ phiếu',
		
		kejian: 'Có thể trừ',
		jubaodh: 'Báo cáo',
		kuaijie: 'Liên kết nhanh',
		gywom: "Về chúng tôi",
		lxwm: 'liên hệ với chúng tôi',
		yszc: 'chính sách bảo mật',
		mztk: 'điều khoản miễn trừ',
		yonghuduan: 'Ứng dụng người dùng',
		sryqm: 'Vui lòng mã mời (nhập vào)',
		hqyzm: 'Nhận mã xác minh',
		qsrssnr: 'Nhập nội dung tìm kiếm',
		sousuo: 'Tìm kiếm',
		gouwuche: 'Giỏ hàng',
		qbsp: 'Tất cả sản phẩm',
		shouye: 'Trang đầu',
		xpzx: 'Trung tâm sản phẩm mới',
		jlzx: 'Trung tâm voucher',
		lxkf: 'Liên hệ chăm sóc khách hàng',
		huidingbu: 'Quay lại đầu trang',
		cainixihuan: 'Tôi nghĩ bạn sẽ thích',
		myorder: 'Đơn hàng của tôi',
		ckgd: 'Xem thêm',
		tishi: 'Gợi ý',
		tcdenglu: 'Xác nhận đăng xuất?？',
		quere: 'Xác nhận',
		quxiao: 'Hủy bỏ',
		ssjieguo: 'Kết quả tìm kiếm',
		zonghe: 'Tổng hợp',
		xiaoliang: 'Lượng bán',
		jiage: 'Giá cả',
		jgqujian: 'Phạm vi giá',
		tjsp: 'Sản phẩm đề xuất',
		spxq: 'Thông tin chi tiết sản phẩm',
		shoucang: 'Lưu',
		fenxiang: 'Chia sẻ',
		yhj: 'Voucher',
		kuanshi: 'Mẫu',
		yanse: 'Màu sắc',
		shulaing: 'Số lượng',
		jian: 'Cái',
		kucun: 'Tồn kho',
		ljgoumai: 'Mua ngay',
		jrgouwuche: 'Thêm vào giỏ hàng',
		sppingjia: 'Đánh giá sản phẩm',
		ljshiyong: 'Lưu',
		qckeyong: 'Áp dụng cho tất cả',
		youxiaoqi: 'Thời hạn đến',
		haopinglv: 'Đánh giá tốt：',
		qunabu: 'Toàn bộ',
		haopl: 'Đánh giá tốt',
		zhongping: 'Trung bình',
		chaping: 'Đánh giá xấu',
		youtu: 'Có hình ảnh',
		guige: 'Quy cách',
		pjxingji: 'Sao đánh giá',
		lxkefu: 'Liên hệ với chăm sóc khách hàng',
		gzshijian: 'Thời gian làm việc',
		dianhua: 'Điện thoại',
		youxiang: 'Mail',
		boda: 'Quay số',
		fuzhi: 'Sao chép',
		spxinxi: 'Thông tin sản phẩm',
		danjia: 'Đơn giá',
		shulaing: 'Số lượng',
		jine: 'Số tiền',
		caozuo: 'Thao tác',
		quanxuan: 'Chọn tất cả',
		shanxhu: 'Xóa bỏ',
		yirushoucang: 'Lưu vào bộ sưu tập',
		
		yxshagnp: 'Sản phẩm đã chọn',
		jian: 'Phần',
		
		
		heji: 'Tổng cộng:',
		jiesuan: 'Thanh toán',
		cnxh: 'Tôi nghĩ bạn sẽ thích',
		gouwuche: 'Giỏ hàng',
		jiesuan: 'Thanh toán',
		shouhuodizhi: 'Địa chỉ nhận hàng',
		mydizhi: 'Bạn chưa nhập địa chỉ nhận hàng, bạn có thể đến',
		xzdizhi: 'Thêm địa chỉ nhận hàng',
		qrxinxi: 'Xác nhận thông tin đơn hàng',
		sptp: 'Hình ảnh sản phẩm',
		spxinxi: 'Thông tin sản phẩm',
		danjia: 'Đơn giá',
		shuliang: 'Số lượng',
		xiaoji: 'Tổng',
		yhj: 'Voucher',
		xzyhj: 'Chọn voucher',
		yf: 'Phí vận chuyển',
		hdfk: 'Thanh toán khi nhận hàng',
		wuliu: 'Logistic',
		liuyan: 'Tin nhắn của tôi',
		jyliuyan: 'Nên giao tiếp với khách trước khi xác nhận bằng tin nhắn',
		spje: 'Số lượng sản phẩm và só tiền',
		yfje: 'Số tiền phải trả',
		tjdingdan: 'Gửi đơn đặt hàng',
		xzdz: 'Thêm địa chỉ nhận hàng mới',
		gs: 'Công ty',
		mrdz: 'Địa chỉ mặc định',
		swmr: 'Đặt thành mặc định',
		binaji: 'Chỉnh sửali',
		delete: 'Xóa bỏ',
		sqdz: 'Địa chỉ nhận hàng',
		qrdd: 'Xác nhận thông tin đặt hàng',
		sptp: 'Hình ảnh sản phẩm',
		spxx: 'Thông tin sản phẩm',
		xtnr: 'Nhập vào nội dung sản phẩm đã xác nhận với người bán',
		tjdd: 'Gửi đơn đặt hàng',
		xinzengdizhi: 'Thêm địa chỉ',
		bq: 'Tem',
		qsrbq: 'Vui lòng nhập tem địa chỉ',
		home: 'Nhà',
		xuexiao: 'Trường học',
		shr: 'Người nhận',
		qsrshr: 'Vui lòng nhập người nhận hàng',
		sir: 'Ông',
		sister: 'Quý cô',
		lxdh: 'Số liên lạc',
		szdq: 'Khu vực',
		xxdz: 'Địa chỉ',
		sedz: 'Đặt làm địa chỉ mặc định',
		quxiao: 'Hủy bỏ',
		bcdz: 'Lưu địa chỉ',
		yfsm: 'Chi tiết phí vận chuyển',
		qrdd: 'Xác nhận đơn hàng',
		tjdd: 'Gửi đơn đặt hàng',
		cjdd: 'Tạo đơn đặt hàng thành công, xin vui lòng thanh toán sớm! mã đơn hàng',
		zfsj: 'Vui lòng thanh toán trong 30 phút, nếu không đơn đặt hàng sẽ tự động hủy bỏ',
		xzzf: 'Chọn phương thức thanh toán',
		ljzf: 'Thanh toan ngay',
		yfje: 'Số tiền phải trả',
		ddcg: 'Thanh toán đơn hàng thành công!',
		zfje: 'Số tiền thanh toán',
		zffs: 'Phương thức thanh toán',
		zfsj: 'Thời gian thanh toán',
		ckdd: 'Kiểm tra đơn đặt hàng',
		fhsy: 'Quay lại trang chủ',
		grxx: 'Thông tin cá nhân',
		tx: 'Ảnh đại diện',
		yqm: 'Mã mời',
		nc: 'Tên tài khoản',
		xb: 'Giới tính',
		nan: 'Nam',
		nv: 'Nữ',
		shengri: 'Ngày sinh',
		baocun: 'Lưu',
		ddzx: 'Trung tâm đặt hàng',
		psdd: 'Vận chuyển đơn hàng',
		wdqb: 'Túi của tôi',
		qbye: 'Số tiền trong ví',
		yhj: 'Phiếu mua hàng',
		qtgn: 'Cac chưc năng khac',
		wdshoucang: 'Bộ sưu tập của tôi',
		wdzuji: 'Hàng hoá đã xem',
		wdxx: 'Tin nhắn của tôi',
		yqhy: 'Mời bạn bè',
		tdtg: 'Nhóm quảng cáo',
		dzgl: 'Quản lý địa chỉ',
		shezhi: 'Cài đặt',
		xgmima: 'Đổi mật khẩu',
		xgyx: 'Sửa mail',
		bzfk: 'Giúp đỡ và phản hồi',
		fywm: 'Về chúng tôi',
		psdd: 'Yêu cầu giao hàng',
		qb: 'Tất cả',
		dfk: 'Chờ thanh toán',
		dfh: 'Chờ gửi hàng',
		dsh: 'Chờ nhận hàng',
		dpj: 'Chờ đánh giá',
		tk: 'Hoàn tiền/sau mua',
		qbrq: 'Tất cả ngày',
		spxx: 'Thông tin sản phẩm',
		shuliang: 'Số lượng',
		spcz: 'Thao tác sản phẩm',
		je: 'Số tiền',
		zhuangtai: 'Tình trạng',
		jycz: 'Thao tác giao dịch',
		ddbh: 'Số đặt hàng',
		sqsh: 'Áp dụng để bán',
		shifu: 'Số tiền trả thực tế',
		dfh: 'Đợi giao hàng',
		ddxq: 'Chi tiết đơn hàng',
		txfh: 'Nhắc giao hàng',
		qfk: 'Chi trả',
		qrsh: 'Xác nhận nhận hàng',
		ckwl: 'Kiểm trả logistic',
		pingjia: 'Đánh giá',
		tuihuo: 'Hoàn hàng',
		djihui: 'Chờ gửi hoàn',
		cxsq: 'Xin huỷ đơn',
		jhxx: 'Gửi lại thông tin',
		tk: 'Tiền hoàn',
		shouhou: 'hoàn',
		scdd: 'Xóa đơn hàng',
		grzx: 'Trung tâm cá nhân',
		gaidd: 'Đơn hàng sẽ giữ cho bạn trong 30 phút (kể từ ngày đặt hàng) và nếu bạn chưa thanh toán sau 30 phút, hệ thống sẽ tự động hủy đơn đặt hàng.',
		tjdd: 'Gửi đơn đặt hàng',
		fkcg: 'Thanh toán thành công',
		ddfh: 'Chờ giao hàng',
		ddsh: 'Chờ nhận',
		shrxx: 'Thông tin người nhận',
		shr: 'Người nhận',
		fkxx: 'Thông tin thanh toán',
		fkfs: 'Phương thức thanh toán',
		lxdh: 'Số liên lạc',
		shdz: 'Địa chỉ giao hàng',
		ddxx: 'Thông tin đặt hàng',
		ddbz: 'Ghi chú đơn hàng',
		ddbh: 'Mã đơn đặt',
		fz: 'Sao chép',
		xdsj: 'Thời gian đặt hàng',
		spje: 'Số tiền sản phẩm',
		yfk: 'Số tiền phải trả',
		xzqxyy: 'Vui lòng chọn lý do hủy đơn',
		qdqx: 'Xác nhận hủy',
		zbqx: 'Không hủy',
		zfsj: 'Thời gian thanh toán',
		sqsh: 'Áp dụng để bán',
		shxz: 'Lựa chọn sau mua',
		tkyy: 'Lý do hoàn tiền',
		tkje: 'Số tiền hoàn',
		tksm: 'Hướng dẫn hoàn hàng',
		qxz: 'Xin vui lòng chọn',
		scpz: 'Tải lên giấy chứng nhận',
		tj: 'Gửi',
		zczh: 'Đăng ký tài khoản',
		qsryx: 'Vui lòng nhập mail',
		qsryz: 'Vui lòng nhập mã xác nhận',
		hqyzm: 'Nhận mã xác nhận',
		qsrmm: 'Vui lòng nhập mật khẩu (6 ~ 12 chữ cái+số)',
		qsryqm: 'Vui lòng nhập mã lời mời (chọn và điền vào)',
		yyzh: 'Đã có tài khoản',
		xdsj: 'Thời gian lên đơn',
		zfsj: 'Thời gian thanh toán',
		wldh: 'Số lô hàng',
		wlmc: 'Tên lô hàng',
		wz: 'Trang web',
		dddpj: 'Đánh giá sản phẩm, người mua khác có thể tham khảo đánh giá của bạn~',
		cpms: 'Mô tả sản phẩm',
		pjms: 'Mô tả đánh giá',
		qsrpj: 'Vui lòng nhập đánh giá của bạn',
		sctp: 'Tải lên hình ảnh',
		nmpk: 'Đánh giá ẩn danh',
		ddywc: 'Đặt đơn thành công, chúc bạn một cuộc sống hạnh phúc ~',
		cxsq: 'Ứng dụng bị hủy',
		xgsq: 'Xin sửa đổi',
		qddptcl: 'Vui lòng đợi xử lý',
		nycgfq: 'Bạn đã gửi đơn hoàn tiền thành công, vui lòng kiên nhẫn chờ đợi。',
		cxsq: 'Ứng dụng bị hủy',
		xgsq: 'Xin sửa đổi',
		xtts: 'Gợi ý hệ thống',
		cxsq: 'Ứng dụng bị hủy',
		djh: 'Đợi trả laij',
		qjhsp: 'Vui lòng gửi lại cho sản phẩm',
		ptyty: 'Sàn bán đã đồng ý hoàn hàng, vui lòng gửi lại sản phẩm và gửi thông tin vận chuyển。',
		jhdz: 'Thông tin gửi trả',
		thdz: 'Địa chỉ hoàn hàng',
		tksm: 'Hướng dẫn hoàn hàng',
		tkxx: 'Thông tin hoàn tiền',
		dqzt: 'Tình trạng hiện tại',
		fwlx: 'Loại dịch vụ',
		tkyy: 'Lý do hoàn hàng',
		tksm: 'Hướng dẫn hoàn hàng',
		tkpz: 'Đền bù',
		txjhxx: 'Điền vào thông tin hoàn hàng',
		thty: 'Để mang lại hoàn hàng kịp thời, công ty chúng tôi khuyên bạn nên chọn chuyển phát nhanh sf hợp tác',
		wldh: 'Số lô hàng',
		lxdh: 'Số liên lạc',
		thsm: 'Hướng dẫn hoàn hàng',
		tkpz: 'Đền bù',
		wldh: 'Số lô hàng',
		wjmm: 'Quên mật khẩu',
		qsryxh: 'Vui lòng nhập mail',
		shywc: 'Sau khi hoàn thành',
		ptytk: 'Sàn bán đã hoàn tiền',
		tkje: 'Số tiền hoàn trả',
		tkzh: 'Tài khoản hoàn lại tiền',
		tksj: 'Thời gian hoàn lại tiền',
		tksm: 'Hướng dẫn hoàn trả',
		tkpz: 'Đền bù',
		wldh: 'Số lô hàng',
		scdd: 'Xóa đơn hàng',
		th: 'Trả hàng',
		shgb: 'Sau khi đóng cửa',
		cxsj: 'Thời gian thu hồi',
		nycs: 'Bạn đã thu hồi đơn xin hoàn lại tiền này. nếu có vấn đề, bạn có thể gửi lại',
		nycgtk: 'Bạn đã gửi thành công một đơn hoàn tiền, vui lòng kiên nhẫn chờ đợi.',
		wdqb: 'Ví tiền',
		yuer: 'Số tiền (tệ)',
		djs: 'Chờ thanh toán (tệ)',
		yemx: 'Chi tiết số tiền',
		qunabu: 'Tất cả',
		srmx: 'Lịch sử thu vào',
		zcjl: 'Lịch sử chi ra',
		djsmx: 'Đợi chi tiết kết toán',
		djslb: 'Danh sách kết toán',
		djs: 'Đợi thanh toán',
		syje: 'Khoản thu nhập:',
		ddbh: 'Mã đơn đặt:',
		ddje: 'Số tiền đơn đặt:',
		ztxj: 'Trực tiếp thu mua lốp xe hơi, dự kiến ​​sẽ nhận được một khoản hoa hồng cho 10 nhân dân tệ',
		jssj: 'Thời gian kết toán',
		jslx: 'Loại kết toán',
		bzsm: 'Cẩm nang hướng dẫn',
		tixian: 'Rút',
		txje: 'Số tiền rút',
		zhye: 'Số dư tài khoản',
		quanbu: 'Tất cả',
		txsq: 'Phí dịch vụ rút tiền 1%',
		txzh: 'Tài khoản rút tiền',
		qsryhk: 'Vui lòng nhập số thẻ ngân hàng rút tiền',
		sqtx: 'Xin rút tiền',
		yemx: 'Chi tiết số tiền',
		nr: 'Nội dung',
		jiner: 'Số tiền',
		zhuangtai: 'Tình trạng',
		shijian: 'Thời gian',
		shenhezhong: 'Đang xem xét',
		shenhebohui: 'Xem xét và loại bỏ',
		yidaozhang: 'Đã đến',
		txgz: 'Quy tắc rút tiền',
		tjcg: 'Đơn đăng ký thành công',
		txzh: 'Tài khoản rút tiền',
		txje: 'Số tiền rút',
		hydl: 'Vui lòng đăng nhập',
		zczh: 'Đăng ký tài khoản',
		wjmm: 'Quên mật khẩu',
		wsy: 'Không sử dụng',
		ygq: 'Hết hạn',
		qx: 'Chọn tất cả',
		qxsc: 'Hủy bỏ bộ sưu tập',
		xl: 'Sản lượng bán hàng',
		wdzj: 'Hàng hoá đã xem',
		shanchu: 'Xóa bỏ',
		ddxx: 'Thông tin đặt hàng',
		ppgg: 'Thông báo nền tảng',
		ddyfh: 'Đơn đặt hàng đã được vận chuyển',
		xtsj: 'Nhắc nhở nâng cấp hệ thống',
		ggxq: 'Chi tiết thông báo',
		yqhy: 'Mời bạn bè',
		yqm: 'Mã mời',
		fuzhi: 'Sao chép',
		tdtg: 'Nhóm quảng cáo',
		ztgrs: 'Tổng số người quảng cáo',
		bydd: 'Đặt hàng trong tháng này',
		ljdd: 'Đơn hàng tích luỹ',
		jrsy: 'Doanh thu ngày',
		bysy: 'Doanh thu tháng',
		ljsy: 'Doanh thu tích lũy',
		qbry: 'Tất cả nhân sự',
		ztry: 'Nhân viên quảng cáo trực tiếp',
		jtry: 'Nhân viên quảng cáo gián tiếp',
		gzsm: 'Quy tắc',
		zhitui: 'Quảng cáo trực tiếp',
		dan: 'Đơn',
		ren: 'Người',
		yuan: 'Tệ',
		zcsj: 'Thời gian đăng ký',
		fxgz: 'Quy tắc phân phối',
		tgdd: 'Đơn hàng quảng cáo',
		yjsy: 'Doanh thu dự kiến',
		sydj: 'Mức thu nhập',
		xdsj: 'Thời gian đặt đơn',
		ddsf: 'Số tiền thanh toán thực tế',
		zcxy: 'Thỏa thuận đăng ký',
		symx: 'Chi tiết doanh thu',
		kssj: 'Thời gian bắt đầu',
		jssj: 'Thời gian kết thúc',
		dzgl: 'Quản lý địa chỉ',
		xzdz: 'Thêm địa chỉ',
		swmr: 'Đặt làm địa chỉ mặc định',
		xiugai: 'Sửa',
		shanchu: 'Xóa bỏ',
		biaoqian: 'Tem',
		qingshuru: 'Vui lòng địa chỉ vào tem',
		shr: 'Người nhận',
		qsrshr: 'Vui lòng nhập tên của người nhận hàng',
		qsrlxdh: 'Vui lòng nhập số điện thoại của bạn',
		bjdz: 'Địa chỉ biên tập',
		qdsc: 'Bạn có chắc chắn xóa địa chỉ?？',
		xgsf: 'Sửa đổi',
		jmmyz: 'Sửa đổi xác minh mật khẩu cũ',
		yxhyzxg: 'Sửa đổi số hộp thư',
		jiumima: 'Mật khẩu cũ',
		qsrjmm: 'Vui lòng nhập mật khẩu cũ',
		xmm: 'Mật khẩu mới',
		qsrmm: 'Vui lòng nhập mật khẩu mới',
		qrxmm: 'Xác nhận mật khẩu mới',
		zcsrxmm: 'Vui lòng nhập lại mật khẩu mới',
		yxyz: 'Chúng tôi muốn xác minh mail để gửi mã xác nhận',
		yzm: 'Mã xác nhận',
		qsryzm: 'Vui lòng nhập mã xác nhận',
		hqyzm: 'Nhận mã xác minh',
		xmm: 'Mật khẩu mới',
		qsrxmm: 'Vui lòng nhập mật khẩu mới',
		qrxmm: 'Xác nhận mật khẩu mới',
		qzcsr: 'Vui lòng nhập lại mật khẩu mới',
		zhzc: 'Đăng ký một tài khoản',
		mmcz: 'Đặt lại mật khẩu',
		mmxg: 'Đổi mật khẩu',
		yjfk: 'Nhận xét',
		dlsb: 'Đăng nhập thất bại',
		zhzx: 'Ghi nhật ký tài khoản',
		qtwt: 'Các vấn đề khác',
		bzfk: 'Giúp đỡ và phản hồi',
		bzxq: 'Chi tiết trợ giúp',
		tjsh: 'Đề xuất dịch vụ sau sale, khi nào sẽ xét duyệt?',
		tjyjfk: 'Nếu bạn có bất kỳ câu hỏi nào khác trong quá trình sử dụng, bạn có thể nhấp trực tiếp [phản hồi ý kiến] để gửi nó ~',
		bzfk: 'Giúp đỡ và phản hồi',
		fklx: 'Nhận xét',
		cpjy: 'Sản phẩm kiến nghị',
		gngz: 'Chức năng lỗi',
		qtwt: 'Các vấn đề khác',
		fkwt: 'Lịch sử phản hồi',
		wtms: 'Mô tả vấn đề',
		wtxxms: 'Mô tả vấn đề chi tiết hơn, nó giúp chúng tôi giải quyết vấn đề nhanh hơn',
		tjfk: 'Gửi phản hồi',
		fksj: 'Hồ sơ phản hồi',
		pthf: 'Nền tảng trả lời',
		gywm: 'Về chúng tôi',
		cnxh: 'Tôi đoán bạn sẽ thích',
		
		
		yfk: 'Đã thu tiền',
		ywc: 'Đã hoàn thành',
		ksrq: 'Ngày bắt đầu',
		jsrq: 'Ngày kết thúc',
		shuoming: 'Mô tả',
		zhi: 'Đến',
		jjsy: 'Lợi nhuận gián tiếp',
		shwc: 'Hoàn thành sau bán hàng',
		tcdl: 'Đăng nhập',
		qdtc: 'Xác nhận bạn muốn thoát đăng nhập?',
		qd: 'Xác định',
		qx: 'Hủy bỏ',
		linjuan: 'Cổ phiếu',
		xzcm: 'Chọn kích thước',
		ljsy: 'Sử dụng ngay',
		man: 'Đầy đủ',
		kejian: 'Có thể trừ',
		ddh: 'Số thứ tự',
		jjcg: 'Giao dịch thành công',
		ddyqx: 'Lệnh đã hủy',
		sy: 'Phần còn lại',
		fk: 'Thanh toán',
		qxdd: 'Hủy đặt hàng',
		ytx: 'Đã nhắc nhở',
		ckph: 'Nhà kho đang chuẩn bị hàng, xin hãy kiên nhẫn',
		zzps: 'Hàng hóa bạn mua đang được giao, hãy kiên nhẫn。',
		ddpjck: 'Đơn đặt hàng được đánh giá và đánh giá của bạn sẽ được người mua khác tham khảo。',
		znyk: 'Đơn đặt hàng đã hoàn thành, chúc bạn một cuộc sống tốt đẹp。',
		yfk: 'Thanh toán',
		yfh: 'Đã gửi',
		ywc:'Đã hoàn thành',
		ypj: 'Đã đánh giá',
		shz: 'Sau khi bán',
		shwc: 'Hoàn thành sau bán hàng',
		yqx: 'Đã hủy',
		yf: 'Chi phí vận chuyển',
		sfk: 'Thanh toán thực',
		wlgs: 'Công ty Logistics',
		qsrwlgs: 'Vui lòng nhập công ty logistics',
		qtxwldh: 'Vui lòng điền vào số logistics',
		qsrxt: 'Vui lòng nhập (tùy chọn)',
		cxsqcg: 'Yêu cầu hủy bỏ thành công',
		dsh: 'Đang được xem xét',
		djh: 'Để gửi lại',
		dtk: 'Đang chờ hoàn tiền',
		shywc: 'Sau khi bán xong',
		shgb:'Đóng cửa sau bán hàng',
		shbh: 'Đánh giá bị bác bỏ',
		qddtk: 'Vui lòng chờ Platform hoàn tiền',
		ptjjtk: 'Nền tảng đã được gửi trở lại, vui lòng chờ nền tảng nhận hàng và hoàn tiền',
		ptcxcl: 'Sau khi nền tảng đồng ý, xin vui lòng trả lại hàng theo địa chỉ trả lại và điền vào số hóa đơn trả lại để gửi. Nếu nền tảng từ chối, bạn có thể sửa đổi ứng dụng của mình và khởi chạy lại và nền tảng sẽ xử lý lại.',
		ptxsyz: 'Nếu không hiệp thương nhất trí với mặt bằng, xin vui lòng không sử dụng đến trả tiền hoặc gửi thư để tránh mặt bằng từ chối nhận. Vui lòng điền thông tin hậu cần trả lại chính hãng, quá hạn không được điền, yêu cầu trả lại sẽ tự động đóng。',
		ptxz: 'Nếu mặt bằng nhận được hàng và kiểm tra không lỗi, sẽ hoàn lại tiền hoạt động cho bạn. Tiền được chuyển về tài khoản bạn đã chọn khi thanh toán.',
		bccx: 'Bạn đã thu hồi yêu cầu hoàn tiền này và nếu có vấn đề chưa được giải quyết, bạn có thể khởi động lại yêu cầu。',
		tjsq: 'Đăng ký',
		ptsh: 'Kiểm toán nền tảng',
		jhsp: 'Gửi hàng trở lại',
		ytk: 'Hoàn tiền',
		jhdz: 'Gửi lại địa chỉ',
		qbzwz: 'Xin vui lòng đảm bảo hàng hóa hoàn chỉnh mà không có vấn đề, chúng tôi sẽ hoàn lại tiền cho bạn ngay sau khi chúng tôi nhận được hàng hóa',
		ylq: 'Đã nhận',
		xyx: 'Hộp thư mới',
		qsrxxx: 'Vui lòng nhập hộp thư mới',
		yijs: 'Giải quyết',
		yjhdfy: 'Dự kiến sẽ nhận được hoa hồng trở lại',
		wjs: 'Chưa tính',
		
		qscyyt: 'Tải ảnh chụp màn hình ứng dụng lên tối đa 6',
		cxsq: 'Đăng ký lại',
		fbsj: 'Thời gian phát hành',
		qbsc: 'Xóa tất cả',
		qsrzyx: 'Vui lòng nhập đúng số hộp thư',
		qgxyhxy: 'Chọn User Agreement',
		
		qsrzqyyh: 'Vui lòng nhập đúng số tài khoản và mật khẩu'
		
		
		
	},
}
