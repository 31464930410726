<template>
	<div class="one_swiper">
		<el-carousel>
			<el-carousel-item style="height:550px;" v-for="(item,index) in lunbolist" :key="index">
				<!-- <el-carousel-item style="height: 640px;" v-for="(item,index) in lunbolist" :key="index"> -->
				<img @click="todetail(item)" style="height: 550px;width: 100%;" :src="item.thumb" alt="">
			</el-carousel-item>
		</el-carousel>
	</div>
</template>
<script>
	export default {
		name: 'zoumadeng',
		data() {
			return {
				lunbolist: [],
				message: ''
			}
		},
		props: ['message'],
		created() {
			this.indexdata()
			console.log(this.message, '阿斯蒂芬');
		},

		methods: {
			todetail(item) {
				console.log(item, '、、、');
				this.$router.push(
					item.href
				)
			},

			//首页数据接口
			indexdata() {
				//获取首页轮播图
				this.$http({
					url: '/m7019/641e624160dd0',
					method: 'post',
					// notoken: true,
					data: JSON.stringify({
						type: 1
					}),
				}).then((res) => {
					console.log(res, 'llll');
					if (res.data.code == 1) {
						// if(this.message==1) {
						this.lunbolist = res.data.data
						// }else if(this.message==2) {
						// 	this.lunbolist = res.data.data.ads2
						// }else {
						// 	this.lunbolist = res.data.data.ads3
						// }
					}
				})
			},

		}
	}
</script>


<style scoped>
	.one_swiper {
		width: 100%;
		height: 550px;
	}

	.el-carousel {
		width: 100%;
		height: 550px;
		/* line-height: 420px; */
	}

	.el-carousel__container {
		width: 100%;
		/* height: 420px; */

	}

	/* 谷歌 */
	::-webkit-scrollbar {
		display: none;
	}

	/* 火狐 */
	.one_swiper {
		overflow: -moz-scrollbars-none;
	}

	.el-carousel__item h3 {
		color: #475669;
		font-size: 18px;
		opacity: 0.75;
		line-height: 550px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n+1) {
		background-color: #d3dce6;
	}

	.el-carousel__indicators--horizontal {
		top: 600px !important;
		left: 50%;
		transform: translateX(-50%);
	}
</style>