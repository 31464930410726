import Vue from 'vue'
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		
		component: HomeView
	},
	//主模块大页面
	{
		path: '/indexHome',
		name: 'indexHome',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/indexHome.vue')
	},
	
	
	
	// 商品详情页面
	{
		path: '/detail',
		name: 'detail',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/huiyuan.vue')
	},
	
	// 会员页面
	
	{
		path: '/huiyuan',
		name: 'huiyuan',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/huiyuan.vue')
	},
	
	// 已开通会员
	{
		path: '/yikaitonghuiyuan',
		name: 'yikaitonghuiyuan',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/yikaitonghuiyuan.vue')
	},
	// 搜索结果
	{
		path: '/sousuojieguo',
		name: 'sousuojieguo',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/sousuojieguo.vue')
	},
	// 消息中心
	{
		path: '/xiaoxizhongxin',
		name: 'xiaoxizhongxin',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/xiaoxizhongxin.vue')
	},
	// 发布
	{
		path: '/fabu',
		name: 'fabu',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/fabu.vue')
	},
	// 发布
	{
		path: '/fabuwd',
		name: 'fabuwd',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/fabuwd.vue')
	},
	// 发文规范
	{
		path: '/fawenguifan',
		name: 'fawenguifan',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/fawenguifan.vue')
	},
	
	// 关于我们
	{
		path: '/guanyuwomen',
		name: 'guanyuwomen',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/guanyuwomen.vue')
	},
	
	
	// 个人资料
	{
		path: '/gerenziliao',
		name: 'gerenziliao',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/gerenziliao.vue')
	},
	
	// 需求发布
	{
		path: '/xuqiufabu',
		name: 'xuqiufabu',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/xuqiufabu.vue')
	},
	
	// 产品标准
	{
		path: '/chanpinbiaozhun',
		name: 'chanpinbiaozhun',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/chanpinbiaozhun.vue')
	},
	// 新产品技术
	{
		path: '/xinchanpin',
		name: 'xinchanpin',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/xinchanpin.vue')
	},
	
	// 新产品
	{
		path: '/xinchanpin',
		name: 'xinchanpin',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/xinchanpin.vue')
	},
	
	// 项目展示
	{
		path: '/xiangmuzhanshi',
		name: 'xiangmuzhanshi',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/xiangmuzhanshi.vue')
	},
	// 新闻资讯详情
	{
		path: '/zixunxiangqing',
		name: 'zixunxiangqing',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/zixunxiangqing.vue')
	},
	
	// 产品标准
	{
		path: '/chanpingxiangqing',
		name: 'chanpingxiangqing',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/chanpingxiangqing.vue')
	},
	
	// 新产品xiangqing
	{
		path: '/xinchanpingxiangqing',
		name: 'xinchanpingxiangqing',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/xinchanpingxiangqing.vue')
	},
	
	// 报名记录
	{
		path: '/baomingjilu',
		name: 'baomingjilu',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/baomingjilu.vue')
	},
	
	// 技术交流
	{
		path: '/jishujiaoliu',
		name: 'jishujiaoliu',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/jishujiaoliu.vue')
	},
	
	// 技术交流详情
	{
		path: '/jishujiaoliudetail',
		name: 'jishujiaoliudetail',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/jishujiaoliudetail.vue')
	},
	
	// 需求发布详情
	{
		path: '/xuqiufabuxiangqing',
		name: 'xuqiufabuxiangqing',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/xuqiufabuxiangqing.vue')
	},
	
	{
		path: '/kejidongtaixq',
		name: 'kejidongtaixq',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/kejidongtaixq.vue')
	},
	
	{
		path: '/qiyetijiao',
		name: 'qiyetijiao',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/qiyetijiao.vue')
	},
	
	
	// 立即购买页面
	{
		path: '/goumai',
		name: 'goumai',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/goumai.vue')
	},
	
	// 支付页面
	{
		path: '/zhifu',
		name: 'zhifu',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/zhifu.vue')
	},
	
	{
		path: '/zhifuchenggong',
		name: 'zhifuchenggong',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/zhifuchenggong.vue')
	},
	
	{
		path: '/gerenzhongxin',
		name: 'gerenzhongxin',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/gerenzhongxin.vue')
	},
	
	{
		path: '/bianjiziliao',
		name: 'bianjiziliao',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/bianjiziliao.vue')
	},
	
	// {
	// 	path: '/binajiziliao',
	// 	name: 'binajiziliao',
	// 	component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/binajiziliao.vue')
	// },
	
	
	{
		path: '/qianbao',
		name: 'qianbao',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/qianbao.vue')
	},
	{
		path: '/youhuijuan',
		name: 'youhuijuan',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/youhuijuan.vue')
	},
	
	{
		path: '/tixian',
		name: 'tixian',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/tixian.vue')
	},
	
	{
		path: '/shoucang',
		name: 'shoucang',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/shoucang.vue')
	},
	
	{
		path: '/zuji',
		name: 'zuji',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/zuji.vue')
	},
	
	{
		path: '/xiaoxi',
		name: 'xiaoxi',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/xiaoxi.vue')
	},
	
	{
		path: '/xiaoxidetail',
		name: 'xiaoxidetail',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/xiaoxidetail.vue')
	},
	
	{
		path: '/yaoqing',
		name: 'yaoqing',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/yaoqing.vue')
	},
	
	{
		path: '/tuiguang',
		name: 'tuiguang',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/tuiguang.vue')
	},
	
	{
		path: '/dizhi',
		name: 'dizhi',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/dizhi.vue')
	},
	
	{
		path: '/xzdizhi',
		name: 'xzdizhi',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/xzdizhi.vue')
	},
	
	{
		path: '/mima',
		name: 'mima',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/mima.vue')
	},
	
	{
		path: '/youxiang',
		name: 'youxiang',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/youxiang.vue')
	},
	
	{
		path: '/fankui',
		name: 'fankui',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/fankui.vue')
	},
	{
		path: '/yijian',
		name: 'yijian',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/yijian.vue')
	},
	
	{
		path: '/bzxiangqing',
		name: 'bzxiangqing',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/bzxiangqing.vue')
	},
	
	{
		path: '/jilu',
		name: 'jilu',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/jilu.vue')
	},
	
	{
		path: '/guanyu',
		name: 'guanyu',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/guanyu.vue')
	},
	{
		path: '/ziliao',
		name: 'ziliao',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/ziliao.vue')
	},
	
	{
		path: '/ddxiangqing',
		name: 'ddxiangqing',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/ddxiangqing.vue')
	},
	{
		path: '/shddxiangqing',
		name: 'shddxiangqing',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/shddxiangqing.vue')
	},
	
	{
		path: '/shouhou',
		name: 'shouhou',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/shouhou.vue')
	},
	
	{
		path: '/pingjia',
		name: 'pingjia',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/pingjia.vue')
	},
	
	{
		path: '/jiesuan',
		name: 'jiesuan',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/jiesuan.vue')
	},
	{
		path: '/tgdingdan',
		name: 'tgdingdan',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/tgdingdan.vue')
	},
	
	{
		path: '/logindex1',
		name: 'logindex1',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/logindex1.vue')
	},
	
	{
		path: '/feilei',
		name: 'feilei',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/feilei.vue')
	},
	
	{
		path: '/gouwuche',
		name: 'gouwuche',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/newview/gouwuche.vue')
	},
	
	
	
	



	
	//热门数据
	{
		path: '/hotData',
		name: 'hotData',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/hotData.vue')
	},
	//墨客金融主页
	{
		path: '/MokefinancialIndex',
		name: 'MokefinancialIndex',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/MokefinancialIndex.vue')
	},
	//个人身份认证
	{
		path: '/personalIdentity',
		name: 'personalIdentity',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/personalIdentity.vue')
	},
	//申请合作(首页产品模块申请合作)（煤业）
	{
		path: '/ApplicationForm',
		name: 'ApplicationForm',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/ApplicationForm.vue')
	},
	// 大宗计算器
	{
		path: '/counterHome',
		name: 'counterHome',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/xiecounter/counterHome.vue')
	},
	{
		path: '/counterHomeDetail',
		name: 'counterHomeDetail',
		component: () => import( /* webpackChunkName: "hotData" */ '../views/xiecounter/counterHomeDetail.vue')
	},
	//关于我们
	{
		path: '/about',
		name: 'about',
		component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
	},


	// 合作方管理
	{
		path: '/PartnerManagement',
		name: 'PartnerManagement',
		component: () => import( /* webpackChunkName: "about" */ '../views/PartnerManagement/PartnerManagement.vue')
	},
	// 合作方管理（黑白名单）
	{
		path: '/BlackandWhiteList',
		name: 'BlackandWhiteList',
		component: () => import( /* webpackChunkName: "about" */ '../views/PartnerManagement/BlackandWhiteList.vue')
	},
	// 合作方管理（合作方详情）
	{
		path: '/PartnerManagementDetail',
		name: 'PartnerManagementDetail',
		component: () => import( /* webpackChunkName: "about" */
			'../views/PartnerManagement/PartnerManagementDetail.vue')
	},
	// 合作方管理（新建合作方）
	{
		path: '/creatPartnerManagement',
		name: 'creatPartnerManagement',
		component: () => import( /* webpackChunkName: "about" */
			'../views/PartnerManagement/creatPartnerManagement.vue')
	},

	// 产品管理
	{
		path: '/productmanagement',
		name: 'productmanagement',
		component: () => import( /* webpackChunkName: "about" */ '../views/ProductManagement/productmanagement.vue')
	},
	// 产品管理（准入材料）
	{
		path: '/accessMaterials',
		name: 'accessMaterials',
		component: () => import( /* webpackChunkName: "about" */ '../views/ProductManagement/accessMaterials.vue')
	},

	// 产品管理（新建产品）
	{
		path: '/newProduct',
		name: 'newProduct',
		component: () => import( /* webpackChunkName: "about" */ '../views/ProductManagement/newProduct.vue')
	},
	// 产品管理（产品数据）
	{
		path: '/ProductData',
		name: 'ProductData',
		component: () => import( /* webpackChunkName: "about" */ '../views/ProductManagement/ProductData.vue')
	},
	// 产品管理（产品x详情）
	{
		path: '/productDetail',
		name: 'productDetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/ProductManagement/productDetail.vue')
	},


	// 风控模块只是非贸易才有
	// 项目管理（风控审核）
	{
		path: '/projectmanagement',
		name: 'projectmanagement',
		component: () => import( /* webpackChunkName: "about" */ '../views/projectmanagement/projectmanagement.vue')
	},
	// 项目管理（风控详情）
	{
		path: '/RiskControldetail',
		name: 'RiskControldetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/projectmanagement/RiskControldetail.vue')
	},
	// 项目管理（新建立项）
	{
		path: '/newproject',
		name: 'newproject',
		component: () => import( /* webpackChunkName: "about" */ '../views/projectmanagement/newproject.vue')
	},
	// 项目管理（立项列表）
	{
		path: '/projectlist',
		name: 'projectlist',
		component: () => import( /* webpackChunkName: "about" */ '../views/projectmanagement/projectlist.vue')
	},
	// 项目管理（立项详情）
	{
		path: '/projectDetail',
		name: 'projectDetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/projectmanagement/projectDetail.vue')
	},
	// 项目管理（基本信息详情）
	{
		path: '/basicInformation',
		name: 'basicInformation',
		component: () => import( /* webpackChunkName: "about" */ '../views/projectmanagement/basicInformation.vue')
	},
	//项目管理（合作申请）
	{
		path: '/Application',
		name: 'Application',
		component: () => import( /* webpackChunkName: "about" */ '../views/projectmanagement/Application.vue')
	},
	//项目管理（合作申请贸易方）
	{
		path: '/ApplicationMy',
		name: 'ApplicationMy',
		component: () => import( /* webpackChunkName: "about" */ '../views/projectmanagement/ApplicationMy.vue')
	},

	//订单管理模块（采购，销售订单）
	{
		path: '/purchaseOrder',
		name: 'purchaseOrder',
		component: () => import( /* webpackChunkName: "about" */ '../views/orderManagement/purchaseOrder.vue')
	},
	//销售订单
	{
		path: '/salesOrder',
		name: 'salesOrder',
		component: () => import( /* webpackChunkName: "about" */ '../views/orderManagement/salesOrder.vue')
	},
	//订单管理模块（新建采购销售订单）
	{
		path: '/newOrder',
		name: 'newOrder',
		component: () => import( /* webpackChunkName: "about" */ '../views/orderManagement/newOrder.vue')
	},
	//新建销售订单
	{
		path: '/newSalesOrder',
		name: 'newSalesOrder',
		component: () => import( /* webpackChunkName: "about" */ '../views/orderManagement/newSalesOrder.vue')
	},


	// 付款记录Paymentrecord（郝琦杰）
	{
		path: '/Paymentrecord',
		name: 'Paymentrecord',
		component: () => import('../views/fundmanagement/Paymentrecord.vue')
	},
	{
		path: '/collectionpresentation',
		name: 'collectionpresentation',
		component: () => import('../views/fundmanagement/Collectionpresentation.vue')
	},
	//收款提报
	// {
	// 	path: '/collectionpresentation',
	// 	name: 'collectionpresentation',
	// 	component: () => import('../views/fundmanagement/Collectionpresentation.vue')
	// },
	// 保证金管理Marginmanagement
	{
		path: '/Marginmanagement',
		name: 'Marginmanagement',
		component: () => import('../views/fundmanagement/Marginmanagement.vue')
	},
	// 新建付款申请
	{
		path: '/Newpaymentapplication',
		name: 'Newpaymentapplication',
		component: () => import('../views/fundmanagement/Newpaymentapplication.vue')
	},
	// 新建收款提报（付款申请）
	{
		path: '/Newcollectionsubmission',
		name: 'Newcollectionsubmission',
		component: () => import('../views/fundmanagement/Newcollectionsubmission.vue')
	},
	// 工作台
	{
		path: '/workbench',
		name: 'workbench',
		component: () => import('../views/fundmanagement/workbench.vue')
	},
	// 资金来源
	{
		path: '/Sourceoffunds',
		name: 'Sourceoffunds',
		component: () => import('../views/fundmanagement/Sourceoffunds.vue')
	},
	// 付款申请
	{
		path: '/paymentapplication',
		name: 'paymentapplication',
		component: () => import('../views/fundmanagement/paymentapplication.vue')
	},
	// 添加资金
	{
		path: '/Addfunds',
		name: 'Addfunds',
		component: () => import('../views/fundmanagement/Addfunds.vue')
	},
	// 新建收款提报Newcollection
	{
		path: '/Newcollection',
		name: 'Newcollection',
		component: () => import('../views/fundmanagement/Newcollection.vue')
	},
	// 新增票据
	{
		path: '/Newbill',
		name: 'Newbill',
		component: () => import('../views/fundmanagement/Newbill.vue')
	},
	// 票据库存
	{
		path: '/Billinventory',
		name: 'Billinventory',
		component: () => import('../views/fundmanagement/Billinventory.vue')
	},
	// 新建开票申请（暂时没有用）
	{
		path: '/NewInvoicingrequisition',
		name: 'NewInvoicingrequisition',
		component: () => import('../views/controloverinvoices/NewInvoicingrequisition.vue')
	},
	// 新建开票申请2
	{
		path: '/NewInvoicingrequisition_xj',
		name: 'NewInvoicingrequisition_xj',
		component: () => import('../views/controloverinvoices/NewInvoicingrequisition_xj.vue')
	},
	// Inputinvoice进项发票
	{
		path: '/Inputinvoice',
		name: 'Inputinvoice',
		component: () => import('../views/controloverinvoices/Inputinvoice.vue')
	},
	// 新建收票申请
	{
		path: '/Newbillreceiptapplication',
		name: 'Newbillreceiptapplication',
		component: () => import('../views/controloverinvoices/Newbillreceiptapplication.vue')
	},
	// 销项发票
	{
		path: '/Outputinvoice',
		name: 'Outputinvoice',
		component: () => import('../views/controloverinvoices/Outputinvoice.vue')
	},
	// Outputinvoicereportt（暂时没有用）
	{
		path: '/Outputinvoicereportt',
		name: 'Outputinvoicereportt',
		component: () => import('../views/controloverinvoices/Outputinvoicereportt.vue')
	},
	//新建销项发票申请（暂时没有用）
	{
		path: '/Newsalesinvoiceapplication',
		name: 'Newsalesinvoiceapplication',
		component: () => import('../views/controloverinvoices/Newsalesinvoiceapplication.vue')
	},
	//墨客设置主页
	{
		path: '/Mokecertificationsystem',
		name: 'Mokecertificationsystem',
		component: () => import('../views/Certificationmanagement/Mokecertificationsystem.vue')
	},


	//合同管理（合同模板）
	{
		path: '/contractTemplate',
		name: 'contractTemplate',
		component: () => import( /* webpackChunkName: "about" */ '../views/ContractManagement/contractTemplate.vue')
	},
	//合同管理模块（采购模块）
	{
		path: '/ContractManagement',
		name: 'ContractManagement',
		component: () => import( /* webpackChunkName: "about" */
			'../views/ContractManagement/ContractManagement.vue')
	},
	//合同管理模块（销售模块）
	{
		path: '/ContractManagement',
		name: 'ContractManagement',
		component: () => import( /* webpackChunkName: "about" */
			'../views/ContractManagement/ContractManagement.vue')
	},
	//合同管理（新建合同采购）
	{
		path: '/newContract',
		name: 'newContract',
		component: () => import( /* webpackChunkName: "about" */ '../views/ContractManagement/newContract.vue')
	},

	//合同管理（采购合同-贸易）
	{
		path: '/procurementContract',
		name: 'procurementContract',
		component: () => import( /* webpackChunkName: "about" */
			'../views/ContractManagement/procurementContract.vue')
	},
	//合同管理（销售合同-贸易）
	{
		path: '/salesContract',
		name: 'salesContract',
		component: () => import( /* webpackChunkName: "about" */ '../views/ContractManagement/salesContract.vue')
	},
	//合同管理（新建合同销售）
	{
		path: '/newsalesContract',
		name: 'newsalesContract',
		component: () => import( /* webpackChunkName: "about" */ '../views/ContractManagement/newsalesContract.vue')
	},


	//结算管理模块（主页）
	{
		path: '/jiesuanguanli',
		name: 'jiesuanguanli',
		component: () => import( /* webpackChunkName: "about" */ '../views/jiesuanGuanli/jiesuanguanli.vue')
	},
	//结算管理模块（结算订单）
	{
		path: '/creatJiSuanOrder',
		name: 'creatJiSuanOrder',
		component: () => import( /* webpackChunkName: "about" */ '../views/jiesuanGuanli/creatJiSuanOrder.vue')
	},


	//收款提报（资金模块）
	{
		path: '/Collectionpresentation',
		name: 'Collectionpresentation',
		component: () => import( /* webpackChunkName: "about" */
			'../views/fundmanagement/Collectionpresentation.vue')
	},

	//审核流程管理
	{
		path: '/AuditProcessManagement',
		name: 'AuditProcessManagement',
		component: () => import( /* webpackChunkName: "about" */
			'../views/AuditProcessManagement/AuditProcessManagement.vue')
	},
	{ //设置 新增用户 editaccount
		path: '/addacount',
		name: 'addacount',
		component: () => import( /* webpackChunkName: "about" */ '../views/setting/addacount.vue')
	},
	//设置 编辑用户 editaccount
	{
		path: '/editaccount',
		name: 'editaccount',
		component: () => import( /* webpackChunkName: "about" */ '../views/setting/editaccount.vue')
	},
	// accountmanage 账号管理
	{
		path: '/accountmanage',
		name: 'accountmanage',
		component: () => import( /* webpackChunkName: "about" */ '../views/setting/accountmanage.vue')
	},
	// operationlog 操作日志
	{
		path: '/operationlog',
		name: 'operationlog',
		component: () => import( /* webpackChunkName: "about" */ '../views/setting/operationlog.vue')
	},
	// rolemanagement 角色管理
	{
		path: '/rolemanagement',
		name: 'rolemanagement',
		component: () => import( /* webpackChunkName: "about" */ '../views/setting/rolemanagement.vue')
	},
	// addrole 新增角色
	{
		path: '/addrole',
		name: 'addrole',
		component: () => import( /* webpackChunkName: "about" */ '../views/setting/addrole.vue')
	},
	// editrole 编辑角色
	{
		path: '/editrole',
		name: 'editrole',
		component: () => import( /* webpackChunkName: "about" */ '../views/setting/editrole.vue')
	},
	//项目管理-基本信息-查看详情
	{
		path: '/management',
		name: 'management',
		component: () => import( /* webpackChunkName: "about" */ '../views/management.vue')
	},
	// 库存管理 stockManagement
	{
		path: '/stockManagement',
		name: 'stockManagement',
		component: () => import( /* webpackChunkName: "about" */ '../views/stockManagement/index.vue')
	},
	// 新增库存 stockManagement
	{
		path: '/addstock',
		name: 'addstock',
		component: () => import( /* webpackChunkName: "about" */ '../views/stockManagement/addstock.vue')
	},
	// 物流管理 
	{
		path: '/LogisticsManagement',
		name: 'LogisticsManagement',
		component: () => import( /* webpackChunkName: "about" */
			'../views/LogisticsManagement/LogisticsManagement.vue')
	},
	//新建物流 addlogistic
	{
		path: '/addlogistic',
		name: 'addlogistic',
		component: () => import( /* webpackChunkName: "about" */ '../views/LogisticsManagement/addlogistic.vue')
	},
	//查看物流 looklogistic
	{
		path: '/looklogistic',
		name: 'looklogistic',
		component: () => import( /* webpackChunkName: "about" */ '../views/LogisticsManagement/looklogistic.vue')
	},
	// 仓储管理管理 WareManagement
	{
		path: '/WareManagement',
		name: 'WareManagement',
		component: () => import( /* webpackChunkName: "about" */ '../views/WareManagement/WareManagement.vue')
	},
	//新建场地 addware
	{
		path: '/addware',
		name: 'addware',
		component: () => import( /* webpackChunkName: "about" */ '../views/WareManagement/addware.vue')
	},
	//查看场地 lookware
	{
		path: '/lookware',
		name: 'lookware',
		component: () => import( /* webpackChunkName: "about" */ '../views/WareManagement/lookware.vue')
	},
	//仓储列表 HomeStoreList
	{
		path: '/HomeStoreList',
		name: 'HomeStoreList',
		component: () => import( /* webpackChunkName: "about" */ '../views/HomeStoreList.vue')
	},
	//仓储物流 HomeStoreList
	{
		path: '/WarehouseLogistics',
		name: 'WarehouseLogistics',
		component: () => import( /* webpackChunkName: "about" */ '../views/WarehouseLogistics.vue')
	},
	// 在线咨询列表 OnlineList
	{
		path: '/OnlineList',
		name: 'OnlineList',
		component: () => import( /* webpackChunkName: "about" */ '../views/OnlineList.vue')
	},
	//登录 LoginIdex
	{
		path: '/LoginIdex',
		name: 'LoginIdex',
		component: () => import( /* webpackChunkName: "about" */ '../views/LoginIdex.vue')
	},
	// 身份认证 personalIdentitynew
	{
		path: '/personalIdentitynew',
		name: 'personalIdentitynew',
		component: () => import( /* webpackChunkName: "about" */ '../views/personalIdentitynew.vue')
	},
	//项目管理-基本信息-查看详情
	{
		path: '/management',
		name: 'management',
		component: () => import( /* webpackChunkName: "about" */ '../views/management.vue')
	},
	// 解江博写的页面
	{
		path: '/ticketsubmission',
		name: 'ticketsubmission',
		component: () => import('../views/xieungrouped/ticketsubmission.vue')
	},

	// 未分组-新建收票提报-
	{
		path: '/newcreatedticketsubmission',
		name: 'newcreatedticketsubmission',
		component: () => import('../views/xieungrouped/newcreatedticketsubmission.vue')
	},

	// 未分组-开票申请
	{
		path: '/applicationticket',
		name: 'applicationticket',
		component: () => import('../views/xieungrouped/applicationticket.vue')
	},
	//墨客金融
	{
		path: '/prodectList',
		name: 'prodectList',
		component: () => import('../views/xieungrouped/prodectList.vue')
	},
	//首页的产品详情
	{
		path: '/prodectdetail',
		name: 'prodectdetail',
		component: () => import('../views/xieungrouped/prodectdetail.vue')

	},
	// 查看详情 lookdetail
	{
		path: '/lookdetail',
		name: 'lookdetail',
		component: () => import('../views/xieungrouped/lookdetail.vue')

	},
	
	
	// 2期地图模块
	{
		path: '/map',
		name: 'map',
		component: () => import('../views/Map/map.vue')
	
	},
	// 地图模块详情(站台)
	{
		path: '/mapdetails',
		name: 'mapdetails',
		component: () => import('../views/Map/mapdetails.vue')
	
	},
	// 地图模块详情(检测点)
	{
		path: '/mapdetailjcd',
		name: 'mapdetailjcd',
		component: () => import('../views/Map/mapdetailjcd.vue')
	
	},
	//新闻资讯
	{
		path: '/news',
		name: 'news',
		component: () => import('../views/News/news.vue')
	
	},
	//新闻资讯详情
	{
		path: '/newsdetail',
		name: 'newsdetail',
		component: () => import('../views/News/newsdetail.vue')
	
	},
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
