// en.js
export default {
	publicheader: {
		message: "消息",
		shopping: "สวัสดีค่ะ/ครับ ยินดีต้อนรับสู่ Thailand Mall",
		Order: 'คำสั่งซื้อของฉัน',
		logo: 'ร้านค้าในประเทศไทย',
		qichepeijian: 'ชุดอะไหล่รถยนต์',
		zhiliang: 'คุณภาพมาตรฐาน เพิ่มประโยชน์เพื่อการพัฒนา',
		hydenglu: 'กรุณาเข้าสู่ระบบ',
		youxianghao: 'กรุณากรอกอีเมลของคุณ',
		srmima: 'กรุณากรอกรหัสผ่าน (ประกอบด้วย 6-12 ตัวอักษรและตัวเลข)',
		denglu: 'เข้าสู่ระบบ',
		zhuce: 'ลงทะเบียนบัญชี',
		wjmima: 'ลืมรหัสผ่าน',
		tongyi: 'ฉันเห็นด้วย',
		zhengce: 'ข้อตกลงและนโยบายการใช้งาน',
		zcxy: 'ข้อตกลงการลงทะเบียนผู้ใช้',
		yszc: 'นโยบายความเป็นส่วนตัว',
		yu: 'ด้วย',
		zixun: 'สายด่วนให้คำปรึกษา',
		jubaodh: 'รายงานเบอร์โทรศัพท์',
		kuaijie: 'เชื่อมต่อด่วน',
		gywom: "เกี่ยวกับเรา",
		lxwm: 'ติดต่อเรา',
		yszc: 'นโยบายความเป็นส่วนตัว',
		mztk: 'ข้อจำกัดความรับผิดชอบ',
		yonghuduan: 'แอปพลิเคชันผู้ใช้',
		sryqm: 'กรุณากรอกรหัสเชิญ (ถ้ามี)',
		hqyzm: 'รับรหัสยืนยัน',
		qsrssnr: 'สิ่งที่คุณต้องการค้นหา',
		sousuo: 'ค้นหา',
		gouwuche: 'ตะกร้าสินค้า',
		qbsp: 'สินค้าทั้งหมด',
		shouye: 'หน้าแรก',
		xpzx: 'สินค้าใหม่',
		jlzx: 'รวมคูปองส่วนลดพิเศษ',
		lxkf: 'ติดต่อฝ่ายบริการลูกค้า',
		huidingbu: 'กลับสู่ด้านบน',
		cainixihuan: 'สิ่งที่คุณน่าจะชอบ',
		myorder: 'คำสั่งซื้อของฉัน',
		ckgd: 'ดูเพิ่มเติม',
		tishi: 'เสนอแนะ',
		tcdenglu: 'คุณแน่ใจว่าจะออกจากระบบ？',
		quere: 'ยืนยัน',
		quxiao: 'ยกเลิก',
		ssjieguo: 'ผลการค้นหา',
		zonghe: 'ผลการค้นหา',
		xiaoliang: 'ยอดขาย',
		jiage: 'ราคา',
		jgqujian: 'ช่วงราคา',
		tjsp: 'สินค้าแนะนำ',
		spxq: 'รายละเอียดสินค้า',
		shoucang: 'รวบรวม',
		fenxiang: 'แชร์',
		yhj: 'คูปองส่วนลด',
		kuanshi: 'รูปแบบ',
		yanse: 'สี',
		shulaing: 'จำนวน',
		jian: 'ชิ้น',
		kucun: 'มีสินค้า',
		ljgoumai: 'ซื้อทันที',
		jrgouwuche: 'ใส่ตะกร้า',
		sppingjia: 'รีวิวสินค้า',
		ljshiyong: 'รับทันที',
		qckeyong: 'พร้อมจำหน่าย',
		youxiaoqi: 'ใช้ได้ถึงวันที่',
		haopinglv: 'เรตติ้งสินค้า：',
		qunabu: 'ทั้งหมด',
		haopl: 'รีวิวดี',
		zhongping: 'รีวิวปานกลาง',
		chaping: 'รีวิวแย่',
		youtu: 'มีรูปภาพ',
		guige: 'มาตรฐานของขนาด',
		pjxingji: 'ระดับดาว',
		lxkefu: 'ติดต่อฝ่ายบริการลูกค้า',
		gzshijian: 'เวลาทำการ',
		dianhua: 'โทรศัพท์',
		youxiang: 'อีเมล',
		boda: 'วิธี',
		fuzhi: 'คัดลอก',
		spxinxi: 'ข้อมูลผลิตภัณฑ์',
		danjia: 'ราคาต่อหน่วย',
		shulaing: 'จำนวน',
		jine: 'จำนวนเงิน',
		caozuo: 'ดำเนินการ',
		quanxuan: 'เลือกทั้งหมด',
		shanxhu: 'ลบออก',
		yirushoucang: 'ย้ายไปยังรายการโปรด',
		yxshagnp: 'เลือกแล้ว 2 รายการ',
		yxshagnp: 'สินค้าที่เลือก',
		jian: 'ชิ้น',
		heji: 'ทั้งหมด：',
		jiesuan: 'ไปยังการชำระเงิน',
		cnxh: 'สิ่งที่คุณน่าจะชอบ',
		gouwuche: 'ตะกร้าสินค้า',
		jiesuan: 'ชำระเงิน',
		shouhuodizhi: 'ที่อยู่จัดส่ง',
		mydizhi: 'คุณยังไม่มีที่อยู่จัดส่ง คุณสามารถไปที่',
		xzdizhi: 'ที่อยู่จัดส่งใหม่',
		qrxinxi: 'ยืนยันข้อมูลการสั่งซื้อ',
		sptp: 'รูปภาพสินค้า',
		spxinxi: 'ข้อมูลผลิตภัณฑ์',
		danjia: 'ราคาต่อหน่วย',
		shuliang: 'จำนวน',
		xiaoji: 'รวมค่าสินค้า',
		yhj: 'คูปองส่วนลด',
		xzyhj: 'เก็บคูปอง',
		yf: 'ค่าขนส่ง',
		hdfk: 'เก็บเงินปลายทาง',
		wuliu: 'การขนส่ง',
		liuyan: 'ฝากข้อความ',
		jyliuyan: 'แนะนำให้ฝากข้อความก่อนการสนทนา',
		spje: 'รายการ, จำนวนรายการ',
		yfje: 'ยอดเงินทั้งหมดที่ต้องชำระ',
		tjdingdan: 'สั่งสินค้า',
		xzdz: 'ที่อยู่จัดส่งใหม่',
		gs: 'บริษัท',
		mrdz: 'ที่อยู่เริ่มต้น',
		swmr: 'ตั้งค่าเริ่มต้น',
		binaji: 'แก้ไข',
		delete: 'ลบออก',
		sqdz: 'ซ่อนที่อยู่',
		qrdd: 'ยืนยันข้อมูลการสั่งซื้อ',
		sptp: 'รูปภาพสินค้า',
		spxx: 'ข้อมูลผลิตภัณฑ์',
		xtnr: 'ทางเลือก: กรอกเนื้อหาที่ได้รับการเจรจาและยืนยันกับผู้ขายแล้ว',
		tjdd: 'สั่งสินค้า',
		xinzengdizhi: 'เพิ่มที่อยู่ใหม่',
		bq: 'ติดป้ายสถานที่',
		qsrbq: 'ตั้งชื่อสถานที่',
		home: 'บ้าน',
		xuexiao: 'โรงเรียน',
		shr: 'ผู้รับ',
		qsrshr: 'กรุณากรอกรายละเอียดผู้รับ',
		sir: 'นาย',
		sister: 'นาง/นางสาว',
		lxdh: 'เบอร์โทรติดต่อ',
		szdq: 'ตำแหน่งที่อยู่',
		xxdz: 'รายละเอียดที่อยู่',
		sedz: 'ตั้งเป็นที่อยู่เริ่มต้น',
		quxiao: 'ยกเลิก',
		bcdz: 'บันทึกที่อยู่',
		yfsm: 'คำแนะนำในการจัดส่ง',
		qrdd: 'ยืนยันรายการ',
		tjdd: 'สั่งสินค้า',
		cjdd: 'สร้างคำสั่งซื้อสำเร็จแล้ว โปรดชำระเงินโดยเร็วที่สุด หมายเลขคำสั่งซื้อ',
		zfsj: 'กรุณาชำระเงินภายใน 30 นาที มิฉะนั้นคำสั่งซื้อจะถูกยกเลิกโดยอัตโนมัติ',
		xzzf: 'เลือกวิธีการชำระเงิน',
		ljzf: 'ชำระทันที',
		yfje: 'ยอดเงินทั้งหมดที่ต้องชำระ',
		ddcg: 'ชำระเงินสำเร็จ!',
		zfje: 'จำนวนเงินที่ชำระ',
		zffs: 'วิธีการชำระเงิน',
		zfsj: 'เวลาชำระเงิน',
		ckdd: 'ตรวจสอบการสั่งซื้อ',
		fhsy: 'กลับไปหน้าแรก',
		grxx: 'ข้อมูลส่วนตัว',
		tx: 'ภาพโปรไฟล์',
		yqm: 'รหัสเชิญ',
		nc: 'ชื่อเล่น',
		xb: 'เพศ',
		nan: 'ชาย',
		nv: 'หญิง',
		shengri: 'วันเกิด',
		baocun: 'บันทึก',
		ddzx: 'ศูนย์กลางคำสั่งซื้อ',
		psdd: 'หมายเลขคำสั่งซื้อ',
		wdqb: 'กระเป๋าเงินของฉัน',
		qbye: 'ยอดเงินคงเหลือในกระเป๋าเงิน',
		yhj: 'คูปองส่วนลด',
		qtgn: 'ฟังก์ชั่นอื่นๆ',
		wdshoucang: 'สิ่งที่ฉันถูกใจ',
		wdzuji: 'ประวัติการเข้าดูของฉัน',
		wdxx: 'ข่าวสารของฉัน',
		yqhy: 'เชิญเพื่อน',
		tdtg: 'การส่งเสริมการตลาด',
		dzgl: 'การจัดการที่อยู่',
		shezhi: 'ติดตั้ง',
		xgmima: 'เปลี่ยนรหัสผ่าน',
		xgyx: 'เปลี่ยนรหัสไปรษณีย์',
		bzfk: 'ความช่วยเหลือและข้อเสนอแนะ',
		fywm: 'เกี่ยวกับเรา',
		psdd: 'หมายเลขคำสั่งซื้อ',
		qb: 'ทั้งหมด',
		dfk: 'ที่ต้องชำระ',
		dfh: 'ที่ต้องจัดส่ง',
		dsh: 'ที่ต้องได้รับ',
		dpj: 'ให้คะแนน',
		tk: 'คืนเงิน/บริการหลังการขาย',
		shouhou: 'บริการหลังการขาย',
		qbrq: 'ระยะเวลาทั้งหมด',
		spxx: 'ข้อมูลผลิตภัณฑ์',
		shuliang: 'จำนวน',
		spcz: 'แก้ไขสินค้า',
		je: 'จำนวนเงิน',
		zhuangtai: 'สถานะ',
		jycz: 'การประมวลผลธุรกรรม',
		ddbh: 'หมายเลขคำสั่งซื้อ',
		sqsh: 'บริการหลังการขาย',
		shifu: 'ยอดที่ต้องชำระจริง',
		dfh: 'ที่ต้องจัดส่ง',
		ddxq: 'รายละเอียดการสั่งซื้อ',
		txfh: 'เตือนร้านค้าให้จัดส่ง',
		qfk: 'ไปยังการชำระเงิน',
		qrsh: 'ยืนยันการรับสินค้า',
		ckwl: 'ตรวจสอบการจัดส่ง',
		pingjia: 'ระดับคะแนน',
		tuihuo: 'คืนสินค้า',
		djihui: 'ที่จะส่งกลับ',
		cxsq: 'ยกเลิกการทำธุรกรรม',
		jhxx: 'ข้อมูลในการส่งสินค้ากลับ',
		tk: 'คืนเงิน',
		scdd: 'ลบคำสั่งซื้อ',
		grzx: 'ศูนย์ข้อมูลส่วนบุคคล',
		gaidd: 'คำสั่งซื้อจะถูกจองไว้ให้คุณเป็นเวลา 30 นาที (นับจากวันที่ทำการสั่งซื้อ) และหากไม่ชำระเงินหลังจาก 30 นาที ระบบจะยกเลิกคำสั่งซื้อโดยอัตโนมัติ.',
		tjdd: 'สั่งซื้อสินค้า',
		fkcg: 'ชำระเงินสำเร็จ',
		ddfh: 'ที่ต้องจัดส่ง',
		ddsh: 'ที่ต้องได้รับ',
		shrxx: 'ข้อมูลผู้รับ',
		shr: 'ผู้รับ',
		fkxx: 'ข้อมูลการชำระเงิน',
		fkfs: 'วิธีการชำระเงิน',
		lxdh: 'เบอร์โทรติดต่อ',
		shdz: 'ที่อยู่จัดส่ง',
		ddxx: 'ข้อมูลการสั่งซื้อ',
		ddbz: 'ประวัติการสั่งซื้อ',
		ddbh: 'หมายเลขคำสั่งซื้อ',
		fz: 'คัดลอก',
		xdsj: 'เวลาที่สั่งซื้อ',
		spje: 'จำนวนรายการ',
		yfk: 'จำนวนเงินที่ต้องชำระ',
		xzqxyy: 'โปรดเลือกเหตุผลในการยกเลิก',
		qdqx: 'ยืนยันการยกเลิก',
		zbqx: 'ไม่ยกเลิก',
		zfsj: 'เวลาชำระเงิน',
		sqsh: 'สมัครงานหลังการขาย',
		shxz: 'ตัวเลือกหลังการขาย',
		tkyy: 'เหตุผลในการคืนสินค้า',
		tkje: 'จำนวนเงินคืน',
		tksm: 'วิธีการคืนเงิน',
		qxz: 'กรุณาเลือก',
		scpz: 'อัพโหลดหลักฐาน',
		tj: 'ส่ง',
		zczh: 'ลงทะเบียนบัญชี',
		qsryx: 'กรุณากรอกอีเมลของคุณ',
		qsryz: 'กรุณากรอกรหัสยืนยัน',
		hqyzm: 'รับรหัสยืนยัน',
		qsrmm: 'กรุณาใส่รหัสผ่าน (6-12 ตัวอักษร + ตัวเลข)',
		qsryqm: 'กรุณากรอกรหัสเชิญ (ถ้ามี)',
		yyzh: 'มีบัญชีอยู่แล้ว ไปเข้าสู่ระบบ',
		xdsj: 'เวลาที่สั่งซื้อ',
		zfsj: 'เวลาชำระเงิน',
		wldh: 'หมายเลขการจัดส่ง',
		wlmc: 'ชือบริษัทขนส่ง',
		wz: 'URL',
		dddpj: 'คำสั่งซื้ออยู่ระหว่างรอการประเมิน และการประเมินของคุณจะใช้เป็นข้อมูลอ้างอิงสำหรับผู้ซื้อรายอื่น～',
		cpms: 'คำอธิบายผลิตภัณฑ์',
		pjms: 'คำอธิบายการประเมิน',
		qsrpj: 'กรุณาประเมินความพึงพอใจ',
		sctp: 'อัพโหลดรูปภาพ',
		nmpk: 'การประเมินผลที่ไม่ระบุชื่อ',
		ddywc: 'คำสั่งซื้อเสร็จสมบูรณ์แล้ว ขอให้คุณมีความสุข～',
		cxsq: 'ยกเลิกการทำธุรกรรม',
		xgsq: 'เปลี่ยนแปลงการทำธุรกรรม',
		qddptcl: 'โปรดรอให้แพลตฟอร์มดำเนินการ',
		nycgfq: 'คุณได้เริ่มต้นการขอคืนเงินสำเร็จแล้ว โปรดรอเพื่อให้แพลตฟอร์มดำเนินการ。',
		cxsq: 'ยกเลิกการทำธุรกรรม',
		xgsq: 'เปลี่ยนแปลงการทำธุรกรรม',
		xtts: 'คำแนะนำของระบบ',
		cxsq: 'ยกเลิกการทำธุรกรรม',
		djh: 'ที่จะส่งกลับ',
		qjhsp: 'กรุณาคืนสินค้า',
		ptyty: 'แพลตฟอร์มได้อนุมัติการขอคืนสินค้าแล้ว โปรดส่งสินค้าคืนและส่งข้อมูลการจัดส่ง。',
		jhdz: 'ข้อมูลในการส่งสินค้ากลับ',
		thdz: 'ที่อยู่ในการส่งสินค้ากลับ',
		tksm: 'วิธีการคืนเงิน',
		tkxx: 'ข้อมูลการคืนเงิน',
		dqzt: 'สถานะปัจจุบัน',
		fwlx: 'ประเภทบริการ',
		tkyy: 'เหตุผลในการคืนสินค้า',
		tksm: 'วิธีการคืนเงิน',
		tkpz: 'ใบรับรองการคืนเงิน',
		txjhxx: 'กรอกข้อมูลการคืนสินค้า',
		thty: 'เพื่อมอบประสบการณ์คืนสินค้าให้ดีขึ้นภายในเวลากำหนด แนะนำให้คุณเลือกใช้บริการของบริษัทขนส่ง Shunfeng',
		wldh: 'หมายเลขการจัดส่ง',
		lxdh: 'เบอร์โทรติดต่อ',
		thsm: 'คำแนะนำในการคืนสินค้า',
		tkpz: 'ใบรับรองการคืนเงิน',
		wldh: 'หมายเลขการจัดส่ง',
		wjmm: 'ลืมรหัสผ่าน',
		qsryxh: 'กรุณากรอกอีเมลของคุณ',
		shywc: 'บริการหลังการขายเสร็จสิ้น',
		ptytk: 'แพลตฟอร์มคืนเงินเรียบร้อยแล้ว',
		tkje: 'จำนวนเงินคืน',
		tkzh: 'บัญชีที่คืนเงิน',
		tksj: 'เวลาคืนเงิน',
		tksm: 'วิธีการคืนเงิน',
		tkpz: 'ใบรับรองการคืนเงิน',
		wldh: 'หมายเลขการจัดส่ง',
		scdd: 'ลบคำสั่งซื้อ',
		th: 'คืนสินค้า',
		shgb: 'ปิดบริการหลังการขาย',
		cxsj: 'เวลาถอนคำสั่ง',
		nycs: 'คุณได้ถอนคำขอคืนเงินนี้แล้ว หากปัญหาไม่ได้รับการแก้ไขสามารถทดลองทำรายการใหม่ได้',
		nycgtk: 'คุณได้เริ่มต้นการขอคืนเงินสำเร็จแล้ว โปรดรอเพื่อให้แพลตฟอร์มดำเนินการ。',
		wdqb: 'กระเป๋าเงินของฉัน',
		yuer: 'ยอดเงินคงเหลือ (หยวน)',
		djs: 'รอการชำระเงิน (หยวน)',
		yemx: 'รายละเอียดยอดเงินคงเหลือ',
		qunabu: 'ทั้งหมด',
		srmx: 'บันทึกรายได้',
		zcjl: 'บันทึกค่าใช้จ่าย',
		djsmx: 'รายละเอียดการชำระบัญชีที่รอดำเนินการ',
		djslb: 'รายการชำระบัญชีที่รอดำเนินการ',
		djs: 'รอการชำระเงิน',
		syje: 'จำนวนรายได้：',
		ddbh: 'หมายเลขคำสั่งซื้อ：',
		ddje: 'จำนวนคำสั่งซื้อ：',
		ztxj: 'กดตรงระดับล่าง Cai Xiaocai ซื้อยางรถยนต์คาดว่าจะได้รับค่าคอมมิชชั่นคืน 10 หยวน',
		jssj: 'เวลาเรียกเก็บเงิน',
		jslx: 'รูปแบบการเรียกเก็บเงิน',
		bzsm: 'คู่มือการใช้งาน',
		tixian: 'ถอน',
		txje: 'จำนวนเงินที่ถอน',
		zhye: 'ยอดเงินในบัญชี',
		quanbu: 'ทั้งหมด',
		txsq: 'ค่าธรรมเนียม 1% สำหรับการถอนเงินสด',
		txzh: 'บัญชีที่ถอนเงิน',
		qsryhk: 'กรุณากรอกหมายเลขบัตรธนาคารเพื่อถอนเงินสด',
		sqtx: 'ทำธุรกรรมถอนเงิน',
		yemx: 'รายละเอียดยอดเงินคงเหลือ',
		nr: 'เนื้อหา',
		jiner: 'จำนวนเงิน',
		zhuangtai: 'สถานะ',
		shijian: 'เวลา',
		shenhezhong: 'อยู่ระหว่างการรีวิว',
		shenhebohui: 'รีวิวถูกปฏิเสธ',
		yidaozhang: 'เติมเงินสำเร็จ',
		txgz: 'กฎการถอนเงิน',
		tjcg: 'ส่งใบสมัครเรียบร้อย',
		txzh: 'ถอนเงินออกจากบัญชี',
		txje: 'จำนวนเงินที่ถอน',
		hydl: 'กรุณาเข้าสู่ระบบ',
		zczh: 'ลงทะเบียนบัญชี',
		wjmm: 'ลืมรหัสผ่าน',
		wsy: 'ไม่ได้ใช้',
		ygq: 'หมดอายุ',
		qx: 'เลือกทั้งหมด',
		qxsc: 'ยกเลิกรายการโปรด',
		xl: 'ยอดขาย',
		wdzj: 'ประวัติการเข้าดูของฉัน',
		shanchu: 'ลบออก',
		ddxx: 'ข้อความการสั่งซื้อ',
		ppgg: 'ประกาศจากแพลตฟอร์ม',
		ddyfh: 'คำสั่งซื้อถูกจัดส่ง',
		xtsj: 'การแจ้งเตือนการอัพเกรดระบบ',
		ggxq: 'รายละเอียดประกาศ',
		yqhy: 'เชิญเพื่อน',
		yqm: 'รหัสเชิญ',
		fuzhi: 'คัดลอก',
		tdtg: 'การส่งเสริมการตลาด',
		ztgrs: 'จำนวนคนที่ทำการส่งเสริมการตลาดทั้งหมด',
		bydd: 'คำสั่งซื้อของเดือน',
		ljdd: 'ยอดสั่งซื้อสะสม',
		jrsy: 'รายได้วันนี้',
		bysy: 'รายได้เดือนนี้',
		ljsy: 'รายได้สะสม',
		qbry: 'พนักงานทั้งหมด',
		ztry: 'คนที่นำสินค้ามาขายโดยตรง',
		jtry: 'ตัวกลางขายสินค้า',
		gzsm: 'กฎ',
		zhitui: 'ขายตรง',
		dan: 'หนึ่ง',
		ren: 'คน',
		yuan: 'หยวน',
		zcsj: 'เวลาลงทะเบียน',
		fxgz: 'กฎการกระจายสินค้า',
		tgdd: 'คำสั่งซื้อโปรโมชั่น',
		yjsy: 'รายได้โดยประมาณ',
		sydj: 'ระดับรายได้',
		xdsj: 'เวลาที่สั่งซื้อ',
		ddsf: 'จำนวนเงินที่จ่ายจริงของคำสั่งซื้อ',
		zcxy: 'ข้อตกลงการลงทะเบียน',
		symx: 'รายละเอียดรายได้',
		kssj: 'เวลาเริ่มต้น',
		jssj: 'เวลาสิ้นสุด',
		dzgl: 'การจัดการที่อยู่',
		xzdz: 'เพิ่มที่อยู่ใหม่',
		swmr: 'ตั้งเป็นที่อยู่เริ่มต้น',
		xiugai: 'เปลี่ยน',
		shanchu: 'ลบออก',
		biaoqian: 'ติดป้ายสถานที่',
		qingshuru: 'ตั้งชื่อสถานที่',
		shr: 'ผู้รับ',
		qsrshr: 'กรุณากรอกชื่อผู้รับ',
		qsrlxdh: 'โปรดกรอกเบอร์โทรศัพท์',
		bjdz: 'แก้ไขที่อยู่',
		qdsc: 'คุณแน่ใจหรือไม่ว่าต้องการลบที่อยู่นี้？',
		xgsf: 'วิธีการแก้ไข',
		jmmyz: 'ยืนยันแก้ไขรหัสผ่าน',
		yxhyzxg: 'ยืนยันแก้ไขเปลี่ยนแปลงอีเมล',
		jiumima: 'รหัสผ่านเก่า',
		qsrjmm: 'กรุณากรอกรหัสผ่านเก่า',
		xmm: 'รหัสผ่านใหม่',
		qsrmm: 'กรุณากรอกรหัสผ่านใหม่',
		qrxmm: 'ยืนยันรหัสผ่านใหม่',
		zcsrxmm: 'กรุณากรอกรหัสผ่านใหม่อีกครั้ง',
		yxyz: 'เราจำเป็นต้องยืนยันที่อยู่อีเมลที่คุณลงทะเบียนไว้',
		yzm: 'รหัสยืนยัน',
		qsryzm: 'กรุณากรอกรหัสยืนยัน',
		hqyzm: 'รับรหัสยืนยัน',
		xmm: 'รหัสผ่านใหม่',
		qsrxmm: 'กรุณากรอกรหัสผ่านใหม่',
		qrxmm: 'ยืนยันรหัสผ่านใหม่',
		qzcsr: 'กรุณากรอกรหัสผ่านใหม่อีกครั้ง',
		zhzc: 'ลงทะเบียนบัญชี',
		mmcz: 'รีเซ็ทรหัสผ่าน',
		mmxg: 'แก้ไขรหัสผ่าน',
		yjfk: 'ข้อเสนอแนะ',
		dlsb: 'เข้าสู่ระบบล้มเหลว',
		zhzx: 'ลบบัญชี',
		qtwt: 'ปัญหาอื่นๆ',
		bzfk: 'ความช่วยเหลือและข้อเสนอแนะ',
		bzxq: 'รายละเอียดการช่วยเหลือ',
		tjsh: 'การติดต่อพนักงานหลังการขายจะได้รับการตรวจสอบเมื่อใด',
		tjyjfk: 'หากคุณมีคำถามอื่นๆ ระหว่างการใช้งาน คุณสามารถคลิกที่คำติชมด้านล่างเพื่อส่งได้โดยตรงs',
		bzfk: 'ความช่วยเหลือและข้อเสนอแนะ',
		fklx: 'ประเภทข้อเสนอแนะ',
		cpjy: 'คำแนะนำผลิตภัณฑ์',
		gngz: 'รายงานความผิดปกติ',
		qtwt: 'ปัญหาอื่นๆ',
		fkwt: 'บันทึกข้อเสนอแนะ',
		wtms: 'คำอธิบายปัญหา',
		wtxxms: 'โปรดอธิบายปัญหาอย่างละเอียดเพื่อเราจะได้ปัญหาให้คุณได้เร็วยิ่งขึ้น',
		tjfk: 'ส่งข้อเสนอแนะ',
		fksj: 'เวลาตอบรับความคิดเห็น',
		pthf: 'การตอบกลับจากแพลตฟอร์ม',
		gywm: 'เกี่ยวกับเรา',
		cnxh: 'สิ่งที่คุณน่าจะชอบ',



		yfk: 'รับเงินแล้ว',
		ywc: 'เสร็จสมบูรณ์',
		ksrq: 'วันที่เริ่มต้น',
		jsrq: 'วันที่สิ้นสุด',
		shuoming: 'คำแนะนำ',
		zhi: 'ถึง',
		jjsy: 'ผลประโยชน์ทางอ้อม',
		shwc: 'เสร็จสมบูรณ์หลังการขาย',
		tcdl: 'ออกจาก Login',
		qdtc: 'ยืนยันการยกเลิกการเข้าสู่ระบบ?',
		qd: 'ระบุ',
		qx: 'การยกเลิก',
		linjuan: 'คูปอง',
		xzcm: 'เลือกขนาด',
		ljsy: 'ใช้ตอนนี้',
		man: 'เต็ม',
		kejian: 'ลดได้',
		ddh: 'หมายเลขการสั่งซื้อ',
		jjcg: 'ความสำเร็จในการเทรด',
		ddyqx: 'ยกเลิกคำสั่งซื้อ',
		sy: 'ส่วนที่เหลือ',
		fk: 'การชำระเงิน',
		qxdd: 'การยกเลิกคำสั่งซื้อ',
		ytx: 'ได้รับการแจ้งเตือน',
		ckph: 'คลังสินค้าอยู่ในสต็อกโปรดอดทน',
		zzps: 'สินค้าที่คุณซื้ออยู่ในการจัดส่งโปรดอดทน。',
		ddpjck: 'คำสั่งซื้อที่จะประเมินความคิดเห็นของคุณจะให้ผู้ซื้อรายอื่นอ้างอิง。',
		znyk: 'คำสั่งซื้อเสร็จสมบูรณ์ขอให้คุณมีชีวิตที่ดี.',
		yfk: 'ชำระเงินแล้ว',
		yfh: 'จัดส่งแล้ว',
		ywc: 'เสร็จสมบูรณ์',
		ypj: 'ได้รับการประเมิน',
		shz: 'หลังการขาย',
		shwc: 'เสร็จสมบูรณ์หลังการขาย',
		yqx: 'ยกเลิกแล้ว',
		yf: 'ค่าจัดส่ง',
		sfk: 'ชำระเงินจริง',
		wlgs: 'บริษัทโลจิสติกส์',
		qsrwlgs: 'โปรดป้อน บริษัท โลจิสติกส์',
		qtxwldh: 'กรุณากรอกหมายเลขสลิปโลจิสติกส์',
		qsrxt: 'กรุณากรอก (เลือก)',
		cxsqcg: 'คำขอเพิกถอนสำเร็จ',
		dsh: 'เพื่อตรวจสอบ',
		djh: 'เพื่อส่งกลับ',
		dtk: 'การคืนเงิน',
		shywc: 'หลังการขายเสร็จสมบูรณ์',
		shgb: 'หลังการขายปิด',
		shbh: 'ตรวจสอบถูกปฏิเสธ',
		qddtk: 'โปรดรอการคืนเงินจากแพลตฟอร์ม',
		ptjjtk: 'แพลตฟอร์มถูกส่งกลับมาโปรดรอแพลตฟอร์มเพื่อรับสินค้าและคืนเงิน',
		ptcxcl: 'หลังจากแพลตฟอร์มยินยอมให้ส่งคืนตามที่อยู่คืนสินค้าที่ให้ไว้และกรอกหมายเลขใบนำส่งคืนเพื่อส่ง หากแพลตฟอร์มปฏิเสธคุณสามารถเริ่มต้นอีกครั้งหลังจากแก้ไขใบสมัครและแพลตฟอร์มจะถูกประมวลผลอีกครั้ง。',
		ptxsyz: 'อย่าใช้เก็บเงินปลายทางหรือไปรษณีย์ธรรมดาโดยไม่ได้รับความยินยอมจากแพลตฟอร์มเพื่อหลีกเลี่ยงการปฏิเสธของแพลตฟอร์ม กรุณากรอกข้อมูลโลจิสติกส์การคืนสินค้าจริง, ยังไม่ได้กรอกข้อมูลล่าช้า, คำขอคืนสินค้าจะปิดโดยอัตโนมัติ。',
		ptxz: 'หากแพลตฟอร์มได้รับสินค้าและตรวจสอบสินค้าไม่ถูกต้องการดำเนินการจะคืนเงินให้คุณ เส้นทางเงินจะถูกส่งคืนไปยังบัญชีที่คุณเลือกเมื่อคุณชำระเงิน。',
		bccx: 'คุณได้ยกเลิกคำขอคืนเงินนี้แล้ว และหากปัญหายังไม่ได้รับการแก้ไข คุณสามารถเริ่มคำขอใหม่ได้。',
		tjsq: 'ส่งใบสมัคร',
		ptsh: 'การตรวจสอบแพลตฟอร์ม',
		jhsp: 'ส่งสินค้ากลับ',
		ytk: 'คืนเงินแล้ว',
		jhdz: 'ส่งกลับที่อยู่',
		qbzwz: 'โปรดรับประกันสินค้าที่สมบูรณ์โดยไม่มีปัญหาเราจะคืนเงินให้คุณทันทีที่ได้รับสินค้า',
		zwjt: 'ภาษาจีนตัวย่อ',
		yw: 'ภาษาไทย',
		ynw: 'เวียตนาม',
		tw: 'ไทย',
		ylq: 'ได้รับแล้ว',
		xyx: 'กล่องจดหมายใหม่',
		qsrxxx: 'โปรดป้อนกล่องจดหมายใหม่',
		
		
		yijs: 'ชำระแล้ว',
		yjhdfy: 'คาดว่าจะได้รับค่าจ้างคืน',
		wjs: 'ไม่คำนวณ',
		
		qscyyt: 'กรุณาอัพโหลดภาพหน้าจอของแอป อัพโหลดได้สูงสุด 6 ภาพ',
		cxsq: 'สมัครใหม่',
		fbsj: 'เวลาโพสต์',
		qbsc: 'ลบทั้งหมด',
		qsrzyx: 'กรุณากรอกหมายเลขกล่องจดหมายที่ถูกต้อง',
		
		qgxyhxy: 'กรุณาเลือกข้อตกลงผู้ใช้',
		qsrzqyyh: 'กรุณากรอกหมายเลขบัญชีและรหัสผ่านที่ถูกต้อง'
		
		

	}
}
